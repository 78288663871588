import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {VisibleClassifiersMode} from '|shared';
import {EntityClassDto, SecurityCategoryDto} from '|api/codebook';
import {IczOnChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {IczInMemoryDatasource} from '@icz/angular-table';
import {
  AbstractFileCloseForm
} from '../abstract-file-close-form';

@Component({
  selector: 'icz-general-file-close-form',
  templateUrl: './general-file-close-form.component.html',
  styleUrls: ['./general-file-close-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralFileCloseFormComponent extends AbstractFileCloseForm implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);

  @Input()
  allEntityClassesDataSource = new IczInMemoryDatasource(() => []);

  securityCategories: SecurityCategoryDto[] = [];
  entityClasses: EntityClassDto[] = [];

  formGroupsToCheck!: string[];

  readonly VisibleClassifiersMode = VisibleClassifiersMode;
}
