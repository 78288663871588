import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, ViewChild} from '@angular/core';
import {
  ColumnDefinition,
  extendDefaultTableConfig,
  FilterType,
  IczTableDataSource,
  TableColumnsData,
  TableComponent
} from '@icz/angular-table';
import {StorageUnitFillerDto} from '|api/document';
import {
  AbstractDocumentDialogsManagerService,
  documentColumnNames,
  DocumentFiltersDataService,
  enumToOptions,
  StorageUnitView
} from '|shared';
import {StorageUnitFillerClassificationAttribute, StorageUnitFillerState} from '|api/commons';
import {
  StorageUnitFillerTab
} from '../storage-unit-filler-dialog/storage-unit-filler-dialog.component';
import {IczOption} from '@icz/angular-form-elements';
import {Observable} from 'rxjs';

enum StorageUnitFillerTableColumn {
  SELECTION = 'selection',
  ID = 'id',
  REJECTED_ENTITIES = 'statistics.entitiesRejectedCount',
  EXPECTED_ENTITIES = 'statistics.entitiesExpectedCount',
  INSERTED_ENTITIES = 'statistics.entitiesInsertedCount',
  STORAGE_UNITS_EXPECTED = 'statistics.storageUnitsExpectedCount',
  STORAGE_UNITS_INSERTED = 'statistics.storageUnitsInsertedCount',
  STATE = 'storageUnitFillerState',
  FINAL_RUN = 'finalRun',
  SCHEDULED_RUN_AT = 'scheduledRunAt',
  LAST_RUN_AT = 'lastRunAt',
  CLASSIFICATION_ATTRIBUTES = 'classificationAttributes',
  ENFORCE_DISPOSAL_SCHEDULE = 'enforceStrictDisposalSchedule',
  ENFORCE_DISPOSAL_YEAR = 'enforceStrictDisposalYear',
  ENFORCE_ENTITY_CLASS = 'enforceStrictEntityClass',
  HUMAN_READABLE_FILTER = 'humanReadableFilter',
  CREATED_BY = 'auditInfo.createdByFunctionalPositionId',
  REGISTRY_OFFICE_ID = 'registryOfficeId',
}

const storageUnitFillerColumnNames: Record<StorageUnitFillerTableColumn, string> = {
  [StorageUnitFillerTableColumn.SELECTION]: 'Výběr',
  [StorageUnitFillerTableColumn.STATE]: 'Stav',
  [StorageUnitFillerTableColumn.FINAL_RUN]: 'Finální',
  [StorageUnitFillerTableColumn.INSERTED_ENTITIES]: 'Vložených entit',
  [StorageUnitFillerTableColumn.REJECTED_ENTITIES]: 'Odmítnutých entit',
  [StorageUnitFillerTableColumn.EXPECTED_ENTITIES]: 'Očekávaných entit',
  [StorageUnitFillerTableColumn.STORAGE_UNITS_INSERTED]: 'Založených UJ',
  [StorageUnitFillerTableColumn.STORAGE_UNITS_EXPECTED]: 'Očekávaných UJ',
  [StorageUnitFillerTableColumn.SCHEDULED_RUN_AT]: 'Datum příštího běhu',
  [StorageUnitFillerTableColumn.LAST_RUN_AT]: 'Datum posledního běhu',
  [StorageUnitFillerTableColumn.CLASSIFICATION_ATTRIBUTES]: 'Klíče',
  [StorageUnitFillerTableColumn.ID]: 'ID dávky',
  [StorageUnitFillerTableColumn.ENFORCE_DISPOSAL_SCHEDULE]: 'Jen pro shodné skartační režimy',
  [StorageUnitFillerTableColumn.ENFORCE_DISPOSAL_YEAR]: 'Jen pro shodný rok vyřazení',
  [StorageUnitFillerTableColumn.ENFORCE_ENTITY_CLASS]: 'Jen pro shodnou věcnou skupinu',
  [StorageUnitFillerTableColumn.HUMAN_READABLE_FILTER]: 'Kritéria výběru objektů',
  [StorageUnitFillerTableColumn.CREATED_BY]: 'Vlastník UJ',
  [StorageUnitFillerTableColumn.REGISTRY_OFFICE_ID]: 'Spisovna',
};

const storageUnitFillerColumnSet = [
  StorageUnitFillerTableColumn.SELECTION,
  StorageUnitFillerTableColumn.ID,
  StorageUnitFillerTableColumn.STATE,
  StorageUnitFillerTableColumn.FINAL_RUN,
  StorageUnitFillerTableColumn.REJECTED_ENTITIES,
  StorageUnitFillerTableColumn.EXPECTED_ENTITIES,
  StorageUnitFillerTableColumn.INSERTED_ENTITIES,
  StorageUnitFillerTableColumn.STORAGE_UNITS_EXPECTED,
  StorageUnitFillerTableColumn.STORAGE_UNITS_INSERTED,
  StorageUnitFillerTableColumn.CLASSIFICATION_ATTRIBUTES,
  StorageUnitFillerTableColumn.CREATED_BY,
  StorageUnitFillerTableColumn.REGISTRY_OFFICE_ID,
  StorageUnitFillerTableColumn.SCHEDULED_RUN_AT,
  StorageUnitFillerTableColumn.LAST_RUN_AT,
  StorageUnitFillerTableColumn.ENFORCE_DISPOSAL_SCHEDULE,
  StorageUnitFillerTableColumn.ENFORCE_DISPOSAL_YEAR,
  StorageUnitFillerTableColumn.ENFORCE_ENTITY_CLASS,
  StorageUnitFillerTableColumn.HUMAN_READABLE_FILTER,
];

function getStorageUnitFillersColumnsData(columns: StorageUnitFillerTableColumn[], functionalPositionTreeOptions: Observable<IczOption[]>): ColumnDefinition<StorageUnitFillerTableColumn>[] {
  const columnDefinitions: ColumnDefinition<StorageUnitFillerTableColumn>[] = [];

  columns.forEach(column => {
    let columnDefinition: ColumnDefinition<StorageUnitFillerTableColumn>;
    switch (column) {
      case StorageUnitFillerTableColumn.SELECTION:
        columnDefinition = {
          id: column,
          displayed: true,
          allowSettingInContextMenu: false,
          label: documentColumnNames[column],
          filterType: FilterType.NONE
        };
        break;
      case StorageUnitFillerTableColumn.CREATED_BY:
        columnDefinition =     {
          id: column,
          label: 'Vlastník UJ',
          list$: functionalPositionTreeOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'fp',
            isTree: true,
          },
        };
        break;
      case StorageUnitFillerTableColumn.STATE:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          list: enumToOptions('storageUnitFillerState', StorageUnitFillerState),
          allowTranslation: true,
          filterType: FilterType.ENUM,
        };
        break;
      case StorageUnitFillerTableColumn.FINAL_RUN:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.BOOLEAN,
        };
        break;
      case StorageUnitFillerTableColumn.ID:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.NUMBER,
        };
        break;
      case StorageUnitFillerTableColumn.INSERTED_ENTITIES:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.NUMBER,
          displayed: false,
        };
        break;
      case StorageUnitFillerTableColumn.REJECTED_ENTITIES:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.NUMBER,
          displayed: false,
        };
        break;
      case StorageUnitFillerTableColumn.EXPECTED_ENTITIES:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.NONE,
          disableSort: true,
          displayed: false,
        };
        break;
      case StorageUnitFillerTableColumn.STORAGE_UNITS_EXPECTED:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.NUMBER,
        };
        break;
      case StorageUnitFillerTableColumn.STORAGE_UNITS_INSERTED:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.NUMBER,
        };
        break;
      case StorageUnitFillerTableColumn.SCHEDULED_RUN_AT:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.DATETIME,
          disableSort: true,
        };
        break;
      case StorageUnitFillerTableColumn.LAST_RUN_AT:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.DATETIME,
        };
        break;
      case StorageUnitFillerTableColumn.ENFORCE_DISPOSAL_SCHEDULE:
      case StorageUnitFillerTableColumn.ENFORCE_DISPOSAL_YEAR:
      case StorageUnitFillerTableColumn.ENFORCE_ENTITY_CLASS:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.BOOLEAN,
          displayed: false,
        };
        break;
      case StorageUnitFillerTableColumn.HUMAN_READABLE_FILTER:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;
      case StorageUnitFillerTableColumn.CLASSIFICATION_ATTRIBUTES:
        columnDefinition = {
          id: column,
          label: storageUnitFillerColumnNames[column],
          allowTranslation: true,
          list: enumToOptions('storageUnitFillerClassificationAttribute', StorageUnitFillerClassificationAttribute),
          filterType: FilterType.ENUM,
        };
        break;
      default:
        return;
    }

    columnDefinitions.push(columnDefinition);
  });

  return columnDefinitions;
}

function getStaticStorageUnitFillersColumnsForView(viewType: StorageUnitView): StorageUnitFillerTableColumn[] {
  switch (viewType) {
    case StorageUnitView.STORAGE_UNIT_FILLERS_TABLE:
      return storageUnitFillerColumnSet;
    default:
      return storageUnitFillerColumnSet;
  }
}

@Component({
  selector: 'icz-storage-unit-fillers-table',
  templateUrl: './storage-unit-fillers.component.html',
  styleUrls: ['./storage-unit-fillers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageUnitFillersComponent {
  @Input({required: true}) tableId!: string;
  @Input({required: true}) viewType!: StorageUnitView;
  @Input({required: true}) dataSource!: IczTableDataSource<StorageUnitFillerDto>;
  @Output() activeRowChanged = new EventEmitter<Nullable<StorageUnitFillerDto>>();

  private documentDialogService = inject(AbstractDocumentDialogsManagerService);
  private documentFiltersDataService = inject(DocumentFiltersDataService);

  @ViewChild('iczTable') iczTable!: TableComponent<StorageUnitFillerTableColumn>;

  selectedRows: Array<StorageUnitFillerDto> = [];

  openStorageUnitFillerDetail(row: StorageUnitFillerDto, tab: StorageUnitFillerTab) {
    if (this.viewType === StorageUnitView.STORAGE_UNIT_FILLERS_TABLE) {
      this.documentDialogService.openStorageUnitFillerDialog({storageUnitFiller: row, isUnitView: false, tab}).subscribe(_ => {
        this.dataSource.reload();
      });
    }
  }

  openStorageUnitFillerDetailSettingsTab(row: StorageUnitFillerDto) {
    this.openStorageUnitFillerDetail(row, StorageUnitFillerTab.SETTINGS);
  }

  openStorageUnitFillerDetailErroredEntities(row: StorageUnitFillerDto) {
    this.openStorageUnitFillerDetail(row, StorageUnitFillerTab.CONTENT_ENTITIES);
  }

  unselectAndReload() {
    this.dataSource.reload(true);
  }

  setActiveRow(row: StorageUnitFillerDto) {
    this.activeRowChanged.emit(row);
  }

  columnsData = new TableColumnsData<string>(getStorageUnitFillersColumnsData(
    getStaticStorageUnitFillersColumnsForView(this.viewType),
    this.documentFiltersDataService.functionalPositionTreeOptions));

  config = extendDefaultTableConfig<StorageUnitFillerTableColumn>({
    defaultFilterColumns: [
      StorageUnitFillerTableColumn.ID,
      StorageUnitFillerTableColumn.CLASSIFICATION_ATTRIBUTES,
      StorageUnitFillerTableColumn.LAST_RUN_AT,
      StorageUnitFillerTableColumn.SCHEDULED_RUN_AT,
      StorageUnitFillerTableColumn.STATE,
      StorageUnitFillerTableColumn.FINAL_RUN,
      StorageUnitFillerTableColumn.REGISTRY_OFFICE_ID,
      StorageUnitFillerTableColumn.CREATED_BY,
    ],
    toolbarConfig: {
      showFilter: true,
      showSavedFilters: true,
      autoOpenFilter: false,
      showReload: true,
      showColumnSelector: true,
      showTools: true,
    },
  });

  protected readonly StorageUnitFillerTableColumn = StorageUnitFillerTableColumn;
}
