import {Component, inject} from '@angular/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {finalize} from 'rxjs/operators';
import {ApiCrossReferenceService, CrossReferenceDeleteDto} from '|api/document';
import {CheckUnsavedFormDialogService, DocumentToastService, DocumentToastType, IFormGroupCheckable,} from '|shared';

export interface CrossReferenceDeleteDialogData {
  ids: number[]
}


@Component({
  selector: 'icz-cross-reference-delete-viewer',
  templateUrl: './cross-reference-delete-viewer.component.html',
  styleUrls: ['./cross-reference-delete-viewer.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class CrossReferenceDeleteViewerComponent implements IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nil>();
  private apiCrossReferenceService = inject(ApiCrossReferenceService);
  private documentToastService = inject(DocumentToastService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected modalData = injectModalData<CrossReferenceDeleteDialogData>();

  form = new IczFormGroup({
    reason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  submit() {
    const requestDto: CrossReferenceDeleteDto = {
      crossReferenceIds: this.modalData.ids,
      deleteReason: this.form.get('reason')?.value,
    };

    this.loadingService.doLoading(
      this.apiCrossReferenceService.crossReferenceDelete({
        body: requestDto,
      }),
      this,
    ).pipe(finalize(() => {
      this.form.reset();
      this.modalRef.close(null);
    })).subscribe(
      {
        next: _ => {
          this.documentToastService.dispatchSimpleInfoToast(DocumentToastType.CROSS_REFERENCE_DELETED);
        },
        error: _ => {
          this.documentToastService.dispatchSimpleErrorToast(DocumentToastType.CROSS_REFERENCES_DELETE_ERROR);
        }
      });
  }

  cancel() {
    this.form.reset();
    this.modalRef.close(null);
  }

}
