import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {VisibleClassifiersMode} from '|shared';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ApiFileService, FileDto} from '|api/document';
import {EntityClassDto, SecurityCategoryDto} from '|api/codebook';
import {debounceTime, startWith} from 'rxjs/operators';
import {IczOnChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {
  AbstractFileCloseForm
} from '../abstract-file-close-form';

@Component({
  selector: 'icz-file-close-form',
  templateUrl: './file-close-form.component.html',
  styleUrls: ['./file-close-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileCloseFormComponent extends AbstractFileCloseForm implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private apiFileService = inject(ApiFileService);

  @Input({required: true})
  file!: FileDto;
  @Input()
  settlementDate!: Date;
  @Input()
  showFileInfo = false;
  @Output()
  closeValid = new EventEmitter<boolean>();
  securityCategories: SecurityCategoryDto[] = [];
  entityClasses: EntityClassDto[] = [];

  formGroupsToCheck!: string[];

  readonly VisibleClassifiersMode = VisibleClassifiersMode;

  override ngOnInit(): void {
    super.ngOnInit();

    this.form.valueChanges.pipe(debounceTime(250), takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.closeValid.emit(this.form.valid);
    });

    this.form.get('entityClassId')?.valueChanges.pipe(
      startWith(this.form.get('entityClassId')!.value),
      takeUntilDestroyed(this.destroyRef))
      .subscribe(entityClassId => {
      if (entityClassId) {
        const settlementDate = (new Date(this.form.get('closeDate')!.value!)).toISOString();
        this.loadingService.doLoading(
          this.apiFileService.fileGetValidDisposalSchedules({
            body: {
              inner: [{
                entityClassId,
                entityId: this.file.id
              }],
              referenceDate: settlementDate
            }
          }),
          this
        ).subscribe(validSchedule => {
          if (validSchedule.resultsPerEntity) {
            this.disposalSchedulePrepare = validSchedule.resultsPerEntity[0];
            this.fillDisposalScheduleSectionWithPrepare(this.disposalSchedulePrepare);
          }
        });
      }
    });
  }
}
