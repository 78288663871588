<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <div class="row">
    <div class="col grow">
      <icz-form-field
        formControlName="reason"
        label="Důvod zrušení vazby"
      ></icz-form-field>
    </div>
  </div>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Zrušit vazbu"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
