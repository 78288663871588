import {ChangeDetectorRef, Component, inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable} from 'rxjs';
import {ClassificationSchemeDto} from '|api/codebook';
import {DocumentSettlementInnerDto, FileForm, RetentionTriggerTypeCode, SettlementType} from '|api/commons';
import {
  ApiDocumentService,
  ApiFileService,
  BulkFileSettlementDto,
  BulkFileSettlementPrepareDto,
  DisposalScheduleComputationDto,
  DisposalScheduleComputationInnerRequestDto,
  DisposalScheduleComputationRequestDto,
  DisposalScheduleComputationResponseDto,
  DocumentDto,
  FileDto,
  FilePrepareCompletionDto,
  FileSettlementByAcknowledgementCreateDto,
  FileSettlementByAssignmentCreateDto,
  FileSettlementByDocumentCreateDto,
  FileSettlementByRecordCreateDto,
  FileSettlementPrepareDto,
  SimplifiedDisposalScheduleDto
} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {
  CheckUnsavedFormDialogService,
  enumToOptions,
  EsslComponentDto,
  esslErrorDtoToToastParameters,
  FileToastService,
  FileToastType,
  IFormGroupCheckable,
  interpolateBackendErrorMessage,
  SKIP_ERROR_DIALOG,
  VisibleClassifiersMode
} from '|shared';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ApiComponentService} from '|api/component';
import {startWith} from 'rxjs/operators';
import {
  adjustSettlementDate
} from '../../../../../../../shared/src/lib/components/shared-business-components/document-toolbar/components/document-settle-dialog/document-settle-model';
import {FileSettleFormComponent} from './file-settle-form/file-settle-form.component';
import {AbstractFileSettleCloseDialogComponent} from './abstract-file-settle-close-dialog.component';
import {
  FileSettleDocumentDetail
} from './file-settle-model';
import {LoadingIndicatorService, TabsComponent} from '@icz/angular-essentials';
import {
  getStartOfTheDay,
  IczFormArray,
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidators,
  TextLength
} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {
  FileSettleStateService
} from './file-settle-state.service';


export function getSettleFileFormGroup() {
  const todayDateString = new Date().toISOString();

  return new IczFormGroup({
    fileSettlementType: new IczFormControl<Nullable<SettlementType>>(SettlementType.BY_DOCUMENT, [IczValidators.required()]),
    fileTypeId: new IczFormControl<Nullable<number>>(null),
    fileForm: new IczFormControl<Nullable<FileForm>>({value : null, disabled: true}),
    relatedDocumentId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]), // required if fileSettlementType is BY_DOCUMENT
    content: new IczFormControl<Nullable<string>>(null), // required if fileSettlementType is BY_RECORD
    reason: new IczFormControl<Nullable<string>>(null), // required if fileSettlementType is BY_RECORD
    settlementDate: new IczFormControl<Nullable<string>>(todayDateString, [IczValidators.required()]),
    entityClassId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    // read-only fields
    classificationSchemeName: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    classificationSchemeValidFrom: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    classificationSchemeValidTo: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    disposalScheduleId: new IczFormControl<Nullable<number>>({value: null, disabled: false}, [IczValidators.required()]),
    doCloseFile: new IczFormControl<Nullable<boolean>>(true),
    closeDate: new IczFormControl<Nullable<string>>({value: todayDateString, disabled: true}),
    disposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    minDisposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    minRetentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    yearOfRetentionPeriodStart: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionTriggerTypeCode: new IczFormControl<Nullable<RetentionTriggerTypeCode>>({value: null, disabled: true}),
    triggerEventCheckYear: new IczFormControl<Nullable<string>>({value: null, disabled: true}, [IczValidators.min((new Date()).getFullYear())]),
    externalRetentionTriggerIds: new IczFormControl<Nullable<string>>(null),
    deleteComments: new IczFormControl<Nullable<boolean>>(null),
    documents: new IczFormArray(() => new IczFormGroup({
      documentRefNumber: new IczFormControl<Nullable<string>>(null),
      documentSubject: new IczFormControl<Nullable<string>>(null, [IczValidators.maxLength(100)], undefined, TextLength.UNLIMITED),
      documentId:  new IczFormControl<Nullable<number>>(null),
      documentTypeId:  new IczFormControl<Nullable<number>>(null),
      components: new IczFormControl<Nullable<EsslComponentDto[]>>(null),
      excludedComponentIds: new IczFormControl<Nullable<number[]>>([]),
    }), [])
  });
}

@Component({
  selector: 'icz-file-settle-dialog',
  templateUrl: './file-settle-dialog.component.html',
  styleUrls: ['./file-settle-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService, FileSettleStateService],
})
export class FileSettleDialogComponent extends AbstractFileSettleCloseDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private apiDocumentService = inject(ApiDocumentService);
  protected apiFileService = inject(ApiFileService);
  private apiComponentService = inject(ApiComponentService);
  protected fileToastService = inject(FileToastService);
  private cd = inject(ChangeDetectorRef);
  private translateService = inject(TranslateService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected override objects = injectModalData<FileDto[]>();

  @ViewChild('tabsComponent') tabsComponent!: TabsComponent;

  override generalTabForm = getSettleFileFormGroup();

  formGroupsToCheck!: string[];
  fileUnsettledDocuments: DocumentSettlementInnerDto[] = [];
  doCloseFile = true;

  minSettlementDate!: Date;
  documentsInFile: FileSettleDocumentDetail[] = [];
  unsettledDocumentSteps: string[] = [];

  showUnsettledDocumentsStepButton = false;

  preselectedComponents: Record<number, EsslComponentDto[]> = {};

  quickSubmit = () => {
    this.submit();
  };

  get showTabs() {
    return this.fileUnsettledDocuments.length > 0 || this.isBulk;
  }

  get singleFileDocuments(): IczFormArray {
    return this.getFileTabForm(this.objects[0].id).get('documents') as IczFormArray;
  }

  get disposalScheduleIdCtrl() {
    return this.generalTabForm.get('disposalScheduleId');
  }

  settlementDateForSingleFile(): Date {
    return new Date(this.getFileTabForm(this.objects[0].id).get('settlementDate')!.value);
  }

  getFileTabForm(fileId: number): IczFormGroup {
    return this.forms[String(fileId)] as IczFormGroup;
  }

  availableDisposalSchedules: Record<string, SimplifiedDisposalScheduleDto[]> = {};
  classificationSchemes: ClassificationSchemeDto[] = [];
  retentionTriggerTypesOptions: IczOption[] = enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode);

  readonly SettlementType = SettlementType;

  readonly VisibleClassifiersMode = VisibleClassifiersMode;

  bulkFilePrepareData: FileSettlementPrepareDto[] = [];

  bulkSettlementDate: Date = new Date();
  globalDisposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>;
  disposalSchedulePreparePerFile: Nullable<DisposalScheduleComputationDto[]>;
  bulkDisposalScheduleOptions: IczOption<number, SimplifiedDisposalScheduleDto>[] = [];

  initBulkFileSettlement(requests$: [Observable<ClassificationSchemeDto[]>, Observable<BulkFileSettlementPrepareDto>]) {
    this.loadingService.doLoading(
      combineLatest(requests$),
      this
    ).subscribe(([classificationSchemes,
                   bulkFilePrepare,
                 ]) => {
      this.classificationSchemes = classificationSchemes;

      this.bulkFileSettlementApplyPrepare(bulkFilePrepare);
    });
  }

  getDisposalSchedulePreparePerDocument(fileId: number): Nullable<DisposalScheduleComputationDto> {
    if (this.disposalSchedulePreparePerFile) {
      return this.disposalSchedulePreparePerFile.find(dsp => dsp.entityId === fileId)!;
    } else {
      return null;
    }
  }

  private bulkFileSettlementApplyPrepare(bulkFilePrepare: BulkFileSettlementPrepareDto) {
    this.bulkFilePrepareData = bulkFilePrepare.prepareDtos!;
    const referenceDate = this.generalTabForm.get('settlementDate')!.value!;
    const fileValidDisposalSchedulesReq: DisposalScheduleComputationRequestDto = {
      referenceDate,
      inner: [],
    };
    this.objects.forEach((file: FileDto) => {
      const filePrepareData = this.bulkFilePrepareData.find(d => d.fileId === file.id)!;
      if (filePrepareData) {
        this.getFileTabForm(file.id).get('relatedDocumentId')!.setValue(filePrepareData.documentForSettleId);
        FileSettleFormComponent.prepareEntityClasses(filePrepareData, this.getFileTabForm(file.id), bulkFilePrepare.classificationScheme!);

        fileValidDisposalSchedulesReq.inner.push({
          entityClassId: filePrepareData.entityClassId,
          entityId: file.id
        });

        const documentsArray = this.getFileTabForm(file.id).get('documents') as IczFormArray;
        filePrepareData.documents!.forEach(doc => {
          documentsArray.incrementSize();
          const documentFormGroup = documentsArray.controls[documentsArray.controls.length - 1];
          documentFormGroup.get('documentId')?.setValue(doc.documentId);
          documentFormGroup.get('documentTypeId')?.setValue(doc.documentTypeId);
          documentFormGroup.get('documentRefNumber')?.setValue(doc!.refNumber);
          documentFormGroup.get('documentSubject')?.setValue(doc!.subject);
          documentFormGroup.get('components')?.setValue(this.preselectedComponents[doc.documentId]);
        });
      }

      this.syncFormsValidityWithTabValidity();
    });

    this.apiFileService.fileGetValidDisposalSchedules({body: fileValidDisposalSchedulesReq}).subscribe(validSchedules => {
      this.patchBulkDisposalSchedules(validSchedules);
    });
  }

  patchBulkDisposalSchedules(validSchedules: DisposalScheduleComputationResponseDto) {
    if (validSchedules.globalResult && validSchedules.globalResult.availableDisposalSchedules) {
      this.globalDisposalSchedulePrepare = validSchedules.globalResult;
      this.disposalSchedulePreparePerFile = validSchedules.resultsPerEntity;
      this.bulkDisposalScheduleOptions = validSchedules.globalResult.availableDisposalSchedules.map(ads => ({value: ads.disposalScheduleId!, label: ads.name!, data: ads}));
      if (validSchedules.resultsPerEntity) {
        validSchedules.resultsPerEntity.forEach(result => {
          this.getFileTabForm(result.entityId!).get('disposalScheduleId')!.setValue(result.recommendedDisposalScheduleId);
          this.getFileTabForm(result.entityId!).get('yearOfRetentionPeriodStart')!.setValue(result.defaultYearOfRetentionPeriodStart);
          this.getFileTabForm(result.entityId!).get('externalRetentionTriggerIds')!.setValue(result.externalRetentionTriggerIds);
          this.getFileTabForm(result.entityId!).recursivelyUpdateValueAndValidity();
          this.syncFormsValidityWithTabValidity();
        });
      }
    }
  }

  initSingleFileSettlement(requests$: [Observable<ClassificationSchemeDto[]>, Observable<DocumentDto[]>, Observable<FilePrepareCompletionDto>]) {
    this.loadingService.doLoading(
      combineLatest(requests$),
      this
    ).subscribe(([
                   classificationSchemes,
                   documentsInFilePage,
                   filePrepare,
                 ]) => {
      this.classificationSchemes = classificationSchemes;
      this.documentsInFile = documentsInFilePage.map((doc: DocumentDto) => ({
        documentId: doc.id!,
        selectedDocumentTypeId: doc.documentTypeId!,
        subject: doc.subject!,
        refNumber: doc.refNumber!
      }));

      const settlementDetails = filePrepare as FilePrepareCompletionDto;
      FileSettleFormComponent.prepareEntityClasses(settlementDetails, this.getFileTabForm(this.objects[0].id), filePrepare.classificationScheme!);
      this.fileUnsettledDocuments = settlementDetails.documents ?? [];
      this.showUnsettledDocumentsStepButton = this.fileUnsettledDocuments.length > 0;

      this.loadingService.doLoading(this.apiComponentService.componentFindComponentsBySetOfDocumentsIds({body: {documentIds: this.fileUnsettledDocuments.map(doc => doc.documentId)}}),
        this)
        .subscribe(components => {
        components.forEach(component => {
          if (this.preselectedComponents[(component as EsslComponentDto).documentId!]) {
            this.preselectedComponents[(component as EsslComponentDto).documentId!].push((component as EsslComponentDto));
          } else {
            this.preselectedComponents[(component as EsslComponentDto).documentId!] = [(component as EsslComponentDto)];
          }
        });

        const documentsArray = this.getFileTabForm(this.objects[0].id).get('documents') as IczFormArray;
        this.fileUnsettledDocuments?.forEach(doc => {
          this.unsettledDocumentSteps.push('resolveDocuments' + doc.documentId);
          const fileDocument = this.documentsInFile.find(docInFile => docInFile.documentId === doc.documentId);
          documentsArray.incrementSize();
          const documentFormGroup = documentsArray.controls[documentsArray.controls.length - 1];
          documentFormGroup.get('documentId')?.setValue(doc.documentId);
          documentFormGroup.get('documentTypeId')?.setValue(doc.documentTypeId);
          documentFormGroup.get('documentRefNumber')?.setValue(fileDocument!.refNumber);
          documentFormGroup.get('documentSubject')?.setValue(fileDocument!.subject);
          documentFormGroup.get('components')?.setValue(this.preselectedComponents[doc.documentId]);
        });
      });
    });
  }

  private initForms() {
    this.objects.forEach(f => {
      const newFileForm = getSettleFileFormGroup();
      newFileForm.get('fileTypeId')!.setValue(f.fileTypeId);
      this.forms[f.id] = newFileForm;
      this.tabsCache[f.id.toString()] = {prepared: false, classificationScheme: null};
    });
  }

  private updateSubformDisposalScheduleIdIfAvailable(fileId: string, form: IczFormGroup, selectedDisposalScheduleId: number) {
    const availableDisposalScheduleIds = this.availableDisposalSchedules[fileId].map(ads => ads.disposalScheduleId);
    if (availableDisposalScheduleIds.includes(selectedDisposalScheduleId)) {
      form.get('disposalScheduleId')!.setValue(selectedDisposalScheduleId);
    } else {
      form.get('disposalScheduleId')!.setValue(null);
    }
  }

  private initBulkSettlementGeneralForm() {
    if (this.objects.every(d => d.entityClassId === this.objects[0].entityClassId)) {
      this.generalTabForm.get('entityClassId')!.setValue(this.objects[0].entityClassId);
    }
    if (this.objects.every(d => d.fileTypeId === this.objects[0].fileTypeId)) {
      this.generalTabForm.get('fileTypeId')!.setValue(this.objects[0].fileTypeId);
    }

    this.generalTabForm.get('entityClassId')!.clearValidators();
    this.generalTabForm.addControl('useGlobalSettings', new IczFormControl(false));
    this.generalTabForm.get('settlementDate')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(settlementDate => {
      if (settlementDate) {
        this.bulkSettlementDate = new Date(settlementDate);
        this.loadingService.doLoading(
          this.apiFileService.filePrepareBulkSettlement({dateTime: settlementDate, body: this.objects.map(f => f.id)}),
          this
        ).subscribe(bulkPrepare => {
          this.bulkFileSettlementApplyPrepare(bulkPrepare);
        });
      }
    });

    // disposalScheduleId is not always desired to override value in tabs
    const overrideFields: string[] = [
      'fileSettlementType',
      'fileTypeId',
      'settlementDate',
      'disposalScheduleId',
      'content',
      'reason',
      'doCloseFile',
      'yearOfRetentionPeriodStart',
      'triggerEventCheckYear',
      'entityClassId'
    ];
    this.setupGeneralTabValueOverride(overrideFields);

    this.generalTabForm.get('entityClassId')!.valueChanges.pipe(
      startWith(this.generalTabForm.get('entityClassId')!.value),
      takeUntilDestroyed(this.destroyRef))
      .subscribe(eClassId => {
        if (eClassId && this.entityClasses.length) {
          const entityClass = this.entityClasses.find(ec => ec.id === eClassId)!;
          const classification = this.classificationSchemes.find(c => c.id === entityClass.classificationSchemeId);
          if (classification) {
            this.generalTabForm.get('classificationSchemeName')!.setValue(classification.name);
            this.generalTabForm.get('classificationSchemeValidFrom')!.setValue(classification.validFrom);
            this.generalTabForm.get('classificationSchemeValidTo')!.setValue(classification.validTo);
          }

          const referenceDate = this.generalTabForm.get('settlementDate')!.value!;
          const innerRequestData: DisposalScheduleComputationInnerRequestDto[] = [];
          this.objects.forEach(obj => {
            innerRequestData.push({
              entityClassId: eClassId,
              entityId: obj.id
            });
          });
          const fileValidDisposalSchedulesReq: DisposalScheduleComputationRequestDto = {
            referenceDate,
            inner: innerRequestData,
          };

          this.apiFileService.fileGetValidDisposalSchedules({body: fileValidDisposalSchedulesReq}).subscribe(validSchedules => {
            this.patchBulkDisposalSchedules(validSchedules);
          });
        }
      });

    // listener for close file together with settle file in 1 UI dialog
    this.generalTabForm.get('doCloseFile')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(shouldClose => {
      if (shouldClose) {
        this.generalTabForm.get('closeDate')!.setValue(new Date().toISOString());
        this.disposalScheduleIdCtrl!.setValidators([IczValidators.required()]);
        this.disposalScheduleIdCtrl!.enable();
      } else {
        this.disposalScheduleIdCtrl!.clearValidators();
        this.disposalScheduleIdCtrl!.disable();
      }
      this.disposalScheduleIdCtrl!.updateValueAndValidity();
    });

    this.disposalScheduleIdCtrl!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedDisposalScheduleId => {
      const selectedDisposalSchedule = this.bulkDisposalScheduleOptions.find(ds => ds.value === selectedDisposalScheduleId);

      if (selectedDisposalSchedule) {
        this.generalTabForm.get('disposalOperationCode')!.setValue(selectedDisposalSchedule.data!.disposalOperationCode);
        this.generalTabForm.get('retentionPeriod')!.setValue(selectedDisposalSchedule.data!.retentionPeriod);
        this.generalTabForm.get('retentionTriggerTypeCode')!.setValue(selectedDisposalSchedule.data!.retentionTriggerTypeCode);
        Object.entries(this.forms).forEach(([fileId, form]) => {
          form.get('disposalScheduleId')!.setValue(selectedDisposalScheduleId);
        });
      }
    });

    this.generalTabForm.get('fileSettlementType')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(fileSettlementType => {
      this.processReasonAndContentFields(fileSettlementType, this.generalTabForm);
      Object.values(this.forms).forEach(formGroup => {
        const settlingDocumentControl = formGroup.get('relatedDocumentId')!;

        if (fileSettlementType === SettlementType.BY_DOCUMENT || fileSettlementType === SettlementType.BY_ASSIGNMENT) {
          settlingDocumentControl.setValidators([IczValidators.required()]);
        }
        else {
          settlingDocumentControl.setValidators([]);
        }

        this.processReasonAndContentFields(fileSettlementType, formGroup);
      });
      this.syncFormsValidityWithTabValidity();
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    this.isBulk = this.objects.length > 1;
    this.codebookService.entityClasses().subscribe(ec => this.entityClasses = ec);
    this.codebookService.disposalSchedules().subscribe(ds => this.disposalSchedules = ds);

    const newestDate = new Date(Math.max(...this.objects.map(f => (new Date(f.auditInfo!.createdAt!)).getTime())));
    this.minSettlementDate = getStartOfTheDay(newestDate);
    this.checkUnsavedService.addUnsavedFormCheck(this, ['generalTabForm']);

    this.initTabs();

    if (!this.objects?.length) return;
    this.initForms();

    if (this.isBulk) {
      const settlementDate = this.generalTabForm.get('settlementDate')!.value!;
      this.initBulkSettlementGeneralForm();
      this.initBulkFileSettlement([
        this.codebookService.classificationSchemes(),
        this.apiFileService.filePrepareBulkSettlement({dateTime: settlementDate, body: this.objects.map(f => f.id)}),
      ]);
    } else {
      this.initSingleFileSettlement([
        this.codebookService.classificationSchemes(),
        this.apiDocumentService.documentFindByFileId({fileId: this.objects[0].id}),
        this.apiFileService.filePrepareSettlement({
          dateTime: new Date().toISOString(),
          id: this.objects[0].id,
        })
      ]);
    }
  }

  private toggleShowValidity(show: boolean) {
    this.tabs.forEach(tab => {
      if (tab.id !== this.GENERAL_TAB) {
        tab.showTabValidity = show;
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  private getSingleFileSettleByAcknowledgment(formValue: BulkFileSettlementDto, innerSettlementDocuments: DocumentSettlementInnerDto[], fileId?: number): FileSettlementByAcknowledgementCreateDto | BulkFileSettlementDto {
    const result =  {
      disposalScheduleId: formValue.disposalScheduleId,
      documents: innerSettlementDocuments,
      fileTypeId: formValue.fileTypeId,
      settlementDate: adjustSettlementDate(formValue.settlementDate!),
      entityClassId: formValue.entityClassId!,
      doCloseFile: formValue.doCloseFile,
      yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
      triggerEventCheckYear: formValue.triggerEventCheckYear,
    };
    if (fileId) {
      (result as BulkFileSettlementDto).settlementType = SettlementType.BY_ACKNOWLEDGEMENT;
      (result as BulkFileSettlementDto).fileId = fileId;
    }
    return result;
  }

  private getSingleFileSettleByAssignment(formValue: BulkFileSettlementDto, innerSettlementDocuments: DocumentSettlementInnerDto[], fileId?: number): FileSettlementByAssignmentCreateDto | BulkFileSettlementDto {
    const result =  {
      relatedDocumentId: formValue.relatedDocumentId!,
      disposalScheduleId: formValue.disposalScheduleId,
      documents: innerSettlementDocuments,
      fileTypeId: formValue.fileTypeId,
      settlementDate: adjustSettlementDate(formValue.settlementDate!),
      entityClassId: formValue.entityClassId!,
      doCloseFile: formValue.doCloseFile,
      yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
      triggerEventCheckYear: formValue.triggerEventCheckYear,
    };
    if (fileId) {
      (result as BulkFileSettlementDto).settlementType = SettlementType.BY_ASSIGNMENT;
      (result as BulkFileSettlementDto).fileId = fileId;
    }
    return result;
  }

  private getSingleFileSettleByRecord(formValue: BulkFileSettlementDto, innerSettlementDocuments: DocumentSettlementInnerDto[], fileId?: number): FileSettlementByRecordCreateDto | BulkFileSettlementDto {
    const result =  {
      content: formValue.content!,
      reason: formValue.reason!,
      disposalScheduleId: formValue.disposalScheduleId,
      documents: innerSettlementDocuments,
      fileTypeId: formValue.fileTypeId,
      settlementDate: adjustSettlementDate(formValue.settlementDate!),
      entityClassId: formValue.entityClassId!,
      doCloseFile: formValue.doCloseFile,
      yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
      triggerEventCheckYear: formValue.triggerEventCheckYear,
    };
    if (fileId) {
      (result as BulkFileSettlementDto).settlementType = SettlementType.BY_RECORD;
      (result as BulkFileSettlementDto).fileId = fileId;
    }
    return result;
  }

  private getSingleFileSettleByDocument(formValue: BulkFileSettlementDto, innerSettlementDocuments: DocumentSettlementInnerDto[], fileId?: number): FileSettlementByDocumentCreateDto | BulkFileSettlementDto {
    const result =  {
      relatedDocumentId: formValue.relatedDocumentId!,
      disposalScheduleId: formValue.disposalScheduleId,
      documents: innerSettlementDocuments,
      fileTypeId: formValue.fileTypeId,
      settlementDate: adjustSettlementDate(formValue.settlementDate!),
      entityClassId: formValue.entityClassId!,
      doCloseFile: formValue.doCloseFile,
      yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
      triggerEventCheckYear: formValue.triggerEventCheckYear,
    };
    if (fileId) {
      (result as BulkFileSettlementDto).settlementType = SettlementType.BY_DOCUMENT;
      (result as BulkFileSettlementDto).fileId = fileId;
    }
    return result;
  }

  submit() {
    if (this.isBulk) {
      this.toggleShowValidity(true);

      const allFormsValid = Object.values(this.forms).every(f => f.valid);
      if (allFormsValid) {
        const body: Array<BulkFileSettlementDto> = [];

        Object.entries(this.forms).forEach(([fileId, f]) => {
          const formValue = f.getRawValue() as BulkFileSettlementDto;

          const innerSettlementDocuments: DocumentSettlementInnerDto[] = formValue.documents!.map((d: any) => ({
            documentId: d.documentId!,
            documentTypeId: d.documentTypeId!,
            excludedComponentIds: d.excludedComponentIds,
          }));

          const fileSettlementType = f.get('fileSettlementType')!.value;
          if (fileSettlementType === SettlementType.BY_ASSIGNMENT) {
            body.push(this.getSingleFileSettleByAssignment(f.getRawValue() as BulkFileSettlementDto, innerSettlementDocuments, parseInt(fileId)) as BulkFileSettlementDto);
          } else if (fileSettlementType === SettlementType.BY_RECORD) {
            body.push(this.getSingleFileSettleByRecord(f.getRawValue(), innerSettlementDocuments, parseInt(fileId)) as BulkFileSettlementDto);
          } else if (fileSettlementType === SettlementType.BY_DOCUMENT) {
            body.push(this.getSingleFileSettleByDocument(f.getRawValue(), innerSettlementDocuments, parseInt(fileId)) as BulkFileSettlementDto);
          } else if (fileSettlementType === SettlementType.BY_ACKNOWLEDGEMENT) {
            body.push(this.getSingleFileSettleByAcknowledgment(f.getRawValue(), innerSettlementDocuments, parseInt(fileId)) as BulkFileSettlementDto);
          }
        });

        this.loadingService.doLoading(this.apiFileService.fileBulkSettle({body}), this)
          .subscribe({
            next: _ => {
              this.fileToastService.dispatchBulkOperationStartedToast(FileToastType.BULK_FILE_SETTLEMENT_START_SUCCESS, {
                [InternalNotificationKey.COUNT]: this.objects.length,
              });
              this.modalRef.close(true);
            },
            error: err => {
              this.fileToastService.dispatchFileErrorToast(
                FileToastType.BULK_FILE_SETTLEMENT_CLOSE_ERROR,
                esslErrorDtoToToastParameters(this.translateService, err.error),
              );
            }
          });
      }
      else {
        this.tabsComponent.selectAndScrollToFirstInvalid();
      }
    }
    else {
      const fileToSettle = this.objects[0];
      const formValue = this.getFileTabForm(fileToSettle.id).getRawValue();
      let request$ = new Observable<unknown>();

      const innerSettlementDocuments: DocumentSettlementInnerDto[] = formValue.documents.map((d: any) => ({
        documentId: d.documentId!,
        documentTypeId: d.documentTypeId!,
        excludedComponentIds: d.excludedComponentIds,
      }));

      if (formValue.fileSettlementType === SettlementType.BY_ACKNOWLEDGEMENT) {
        request$ = this.apiFileService.fileSettleFileByAcknowledgement(
          {
            id: fileToSettle.id,
            body: this.getSingleFileSettleByAcknowledgment(formValue, innerSettlementDocuments) as FileSettlementByAcknowledgementCreateDto,
          },
          SKIP_ERROR_DIALOG);
      } else if (formValue.fileSettlementType === SettlementType.BY_ASSIGNMENT) {
        request$ = this.apiFileService.fileSettleFileByAssignment(
          {
            id: fileToSettle.id,
            body: this.getSingleFileSettleByAssignment(formValue, innerSettlementDocuments) as FileSettlementByAssignmentCreateDto,
          },
          SKIP_ERROR_DIALOG
        );
      } else if (formValue.fileSettlementType === SettlementType.BY_DOCUMENT) {
        request$ = this.apiFileService.fileSettleFileByDocument(
          {
            id: fileToSettle.id,
            body: this.getSingleFileSettleByDocument(formValue, innerSettlementDocuments) as FileSettlementByDocumentCreateDto,
          },
          SKIP_ERROR_DIALOG
        );
      } else if (formValue.fileSettlementType === SettlementType.BY_RECORD) {
        request$ = this.apiFileService.fileSettleFileByRecord(
          {
            id: fileToSettle.id,
            body: this.getSingleFileSettleByRecord(formValue, innerSettlementDocuments) as FileSettlementByRecordCreateDto,
          },
          SKIP_ERROR_DIALOG
        );
      }

      this.loadingService.doLoading(
        request$,
        this
      ).subscribe({
        next: _ => {
          this.fileToastService.dispatchFileWarningToast(FileToastType.FILE_SETTLEMENT_STARTED, {
            [InternalNotificationKey.FILE_ID]: fileToSettle.id,
            [InternalNotificationKey.FILE_SUBJECT]: fileToSettle.subject!,
          });
          this.close(true);
        },
        error: err => {
          const errorResponse = JSON.parse(err.error);
          this.dialogService.showError(interpolateBackendErrorMessage(this.translateService, errorResponse),  'Vyřízení obsahuje chyby');
        }
      });
    }
  }

  close(isDone?: boolean) {
    this.modalRef.close(isDone);
  }
}
