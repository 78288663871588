<icz-subjects-table
  [dataSource]="dataSource"
  [columnSet]="SubjectTableColumnSet.RESULT_SET_FOR_DETAIL"
  (selectedRowsChanged)="onRowClick($event)"
  (reloadDataSource)="setDataSource()"
  (contextMenuRequested)="toolbar.openContextMenu($event)"
  [waiting]="loadingIndicatorService.isLoading(this)"
  [parentEntityId]="abstractObjectDetailService.objectId$ | async"
  [parentEntityType]="parentEntityType"
  class="w-full"
  tableTitle="Související subjekty"
  tableId="subjects-overview"
>
  @if (abstractObjectDetailService.object) {
    <icz-subjects-toolbar-buttons
      tools #toolbar
      [entity]="abstractObjectDetailService.object"
      [selectedRows]="selectedRows"
      [view]="SubjectToolbarView.DOCUMENT_OR_FILE_DETAIL"
      (operationCompleted)="toolbarOperationCompleted($event)"
    ></icz-subjects-toolbar-buttons>
  }
</icz-subjects-table>
