import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  CrossReferenceCreateViewerComponent
} from './components/cross-reference-create-viewer/cross-reference-create-viewer.component';
import {SharedModule, SubjectsModule} from '|shared';
import {
  CrossReferenceDeleteViewerComponent
} from './components/cross-reference-delete-viewer/cross-reference-delete-viewer.component';
import {CrossReferenceTableComponent} from './components/cross-reference-table/cross-reference-table.component';
import {
  DocumentFileDeactivationDialogComponent
} from './components/document-file-deactivation-dialog/document-file-deactivation-dialog.component';
import {EsslComponentCountsComponent} from './components/essl-component-counts/essl-component-counts.component';
import {FileCloseDialogComponent} from './components/file-close-dialog/file-close-dialog.component';
import {FileSettleDialogComponent} from './components/file-settle-dialog/file-settle-dialog.component';
import {
  FileSettleDocumentDetailDialogComponent
} from './components/file-settle-dialog/file-settle-document-detail/file-settle-document-detail-dialog.component';
import {NewFileFormComponent} from './components/new-file-form/new-file-form.component';
import {
  EsslObjectDetailComponent
} from './components/essl-object-detail/essl-object-detail.component';
import {
  StorageUnitToolbarButtonsComponent
} from './components/storage-unit-toolbar-buttons/storage-unit-toolbar-buttons.component';
import {
  NewStorageUnitSpecificationFormComponent
} from './components/new-storage-unit-specification-form/new-storage-unit-specification-form.component';
import {
  NewStorageUnitDialogComponent
} from './components/new-storage-unit-dialog/new-storage-unit-dialog.component';
import {
  ObjectAssociationAlertComponent
} from './components/object-association-alert/object-association-alert.component';
import {
  StorageUnitInsertDialogComponent
} from './components/storage-unit-insert-dialog/storage-unit-insert-dialog.component';
import {
  StorageUnitSelectorDialogComponent
} from './components/storage-unit-selector-dialog/storage-unit-selector-dialog.component';
import {FileSettleFormComponent} from './components/file-settle-dialog/file-settle-form/file-settle-form.component';
import {FileCloseFormComponent} from './components/file-close-dialog/file-close-form/file-close-form.component';
import {
  TransferToRegistryOfficeDialogComponent
} from './components/transfer-to-registry-office-dialog/transfer-to-registry-office-dialog.component';
import {
  TransferActivityTakeoverDialogComponent
} from './components/transfer-activity-takeover-dialog/transfer-activity-takeover-dialog.component';
import {
  SuspendOrResumeSettlementDialogComponent
} from './components/suspend-or-resume-settlement-dialog/suspend-or-resume-settlement-dialog.component';
import {
  ObjectDetailTransferIncidentsComponent
} from './components/object-detail-transfer-incidents/object-detail-transfer-incidents.component';
import {
  ObjectDetailPreviewTransferInfoSectionComponent
} from './components/object-detail-preview-transfer-info-section/object-detail-preview-transfer-info-section.component';
import {
  FileSettleFileContentComponent
} from './components/file-settle-dialog/file-settle-file-content/file-settle-file-content.component';
import {
  EditRetentionYearDialogComponent
} from './components/edit-retention-year-dialog/edit-retention-year-dialog.component';
import {
  GeneralFileSettleFormComponent
} from './components/file-settle-dialog/general-file-settle-form/general-file-settle-form.component';
import {
  GeneralFileCloseFormComponent
} from './components/file-close-dialog/general-file-close-form/general-file-close-form.component';
import {ObjectDetailSubjectsComponent} from './components/object-detail-subjects/object-detail-subjects.component';
import {
  StrongCrossReferenceCreateDialogComponent
} from './components/strong-cross-reference-create-dialog/strong-cross-reference-create-dialog.component';
import {
  DocumentFileDisposeDialogComponent
} from './components/document-file-dispose-dialog/document-file-dispose-dialog.component';
import {
  CrossReferenceToolbarButtonsComponent
} from './components/cross-reference-table/cross-reference-toolbar-buttons/cross-reference-toolbar-buttons.component';
import {
  StorageUnitsComponent
} from './components/storage-units/storage-units.component';
import {
  StorageUnitFillersComponent
} from './components/storage-unit-fillers/storage-unit-fillers.component';
import {
  StorageUnitFillerDialogComponent
} from './components/storage-unit-filler-dialog/storage-unit-filler-dialog.component';
import {
  StorageUnitFillerContentToolbarButtonsComponent
} from './components/storage-unit-filler-dialog/storage-unit-filler-content-toolbar-buttons/storage-unit-filler-content-toolbar-buttons.component';
import {
  StorageUnitFillerSelectionDialogComponent
} from './components/storage-unit-filler-dialog/storage-unit-filler-selection-dialog/storage-unit-filler-selection-dialog.component';

@NgModule({
  declarations: [
    EsslComponentCountsComponent,
    FileSettleDialogComponent,
    CrossReferenceCreateViewerComponent,
    CrossReferenceDeleteViewerComponent,
    CrossReferenceTableComponent,
    FileCloseDialogComponent,
    FileSettleDocumentDetailDialogComponent,
    DocumentFileDeactivationDialogComponent,
    NewFileFormComponent,
    EsslObjectDetailComponent,
    StorageUnitToolbarButtonsComponent,
    NewStorageUnitDialogComponent,
    NewStorageUnitSpecificationFormComponent,
    ObjectAssociationAlertComponent,
    StorageUnitInsertDialogComponent,
    StorageUnitSelectorDialogComponent,
    FileSettleFormComponent,
    FileCloseFormComponent,
    FileSettleFileContentComponent,
    TransferToRegistryOfficeDialogComponent,
    TransferActivityTakeoverDialogComponent,
    SuspendOrResumeSettlementDialogComponent,
    ObjectDetailTransferIncidentsComponent,
    ObjectDetailPreviewTransferInfoSectionComponent,
    EditRetentionYearDialogComponent,
    GeneralFileSettleFormComponent,
    GeneralFileCloseFormComponent,
    ObjectDetailSubjectsComponent,
    StrongCrossReferenceCreateDialogComponent,
    DocumentFileDisposeDialogComponent,
    StorageUnitsComponent,
    StorageUnitFillersComponent,
    CrossReferenceToolbarButtonsComponent,
    StorageUnitFillerDialogComponent,
    StorageUnitFillerSelectionDialogComponent,
    StorageUnitFillerContentToolbarButtonsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SubjectsModule
  ],
  exports: [
    EsslComponentCountsComponent,
    FileSettleDialogComponent,
    FileCloseDialogComponent,
    CrossReferenceCreateViewerComponent,
    CrossReferenceTableComponent,
    NewFileFormComponent,
    EsslObjectDetailComponent,
    StorageUnitToolbarButtonsComponent,
    ObjectAssociationAlertComponent,
    FileCloseFormComponent,
    ObjectDetailTransferIncidentsComponent,
    ObjectDetailPreviewTransferInfoSectionComponent,
    ObjectDetailSubjectsComponent,
    StorageUnitsComponent,
    StorageUnitFillersComponent,
    StorageUnitFillerDialogComponent,
  ],
})
export class DocumentSharedModule {
}
