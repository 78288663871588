@if (dataSource) {
  <icz-table
    id="cross-reference-table"
    [columnsData]="columnsData"
    [dataSource]="dataSource"
    [rowSelectionDisabler]="rowSelectionDisabler"
    (selectedRowsChanged)="selectedRows = $event"
    (contextMenuRequested)="toolbar.openContextMenu($event)"
  >

    <icz-label tableTitle label="Související"></icz-label>
    <icz-label notFoundLabel noDataLabel label="Žádné související objekty"></icz-label>
    <icz-cross-reference-toolbar-buttons
      tools #toolbar
      [selectedRows]="selectedRows"
      [esslEntity]="esslEntity"
      (operationCompleted)="onOperationCompleted()"
    ></icz-cross-reference-toolbar-buttons>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'objectClass'">
      <icz-object-class-icon [objectClass]="$any(row.objectClass)"></icz-object-class-icon>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'refNumber'">
      <icz-ref-number-link
        [entity]="getEntityLink(row)"
      ></icz-ref-number-link>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'ownerFunctionalPositionId'; withEllipsis: true">
      @if (row.ownerFunctionalPositionId | findFunctionalPositionById | async; as owner) {
        <span [iczTooltip]="owner">
          {{ owner }}
        </span>
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'createdAt'">
      <span>{{row.createdAt | localizedDatetime}}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'role'; withEllipsis: true">
      <span>{{thisEntityRoleInReference(row)}}</span>
    </ng-container>

  </icz-table>
}
