import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {ApiStorageUnitFillerService, StorageUnitFillerContentDto, StorageUnitFillerDto} from '|api/document';

import {Observable, of} from 'rxjs';
import {Button} from '../../../../../../../../shared/src/lib/components/button-collection/button-collection.component';
import {DialogService} from '@icz/angular-modal';
import {
  AbstractToolbarButtonsComponent,
  AuthorizedButtonService, CommonToolbarButtonValidators,
  TOOLBAR_BUTTONS_COMPONENT_STYLES,
  TOOLBAR_BUTTONS_COMPONENT_TEMPLATE
} from '|shared';
import { StorageUnitFillerToolbarValidators } from '../../../../../../../../shared/src/lib/components/shared-business-components/document-toolbar/services/storage-unit-filler-toolbar.validators';

@Component({
  selector: 'icz-storage-unit-filler-content-toolbar-buttons',
  template: TOOLBAR_BUTTONS_COMPONENT_TEMPLATE,
  styles: TOOLBAR_BUTTONS_COMPONENT_STYLES,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageUnitFillerContentToolbarButtonsComponent extends AbstractToolbarButtonsComponent<StorageUnitFillerContentDto, void> {

  private authorizedButtonService = inject(AuthorizedButtonService);
  private apiStorageUnitFillerService = inject(ApiStorageUnitFillerService);
  private dialogService = inject(DialogService);

  @Input({required: true})
  storageUnitFiller!: StorageUnitFillerDto;

  protected getButtonObs(selection: Array<StorageUnitFillerContentDto>): Observable<Button[]> {
    return of(this.authorizedButtonService.evaluateButtonDefinition([
      {
        label: 'Vyloučit ze zpracování',
        icon: 'handover_to_distribution',
        buttonValidators: [
          CommonToolbarButtonValidators.isNoItemSelected(selection),
          StorageUnitFillerToolbarValidators.isNotStorageUnitPlanned([this.storageUnitFiller!])
        ],
        action: () => this.withdrawEntitiesFromFiller(selection),
      }
    ]));
  }

  private withdrawEntitiesFromFiller(selection: Array<StorageUnitFillerContentDto>) {
    this.dialogService.openQuestionDialogWithAnswer({
      title: 'Vyloučit entity ze zpracování',
      description: `Tuto operaci nelze vrátit.`,
      question: 'Opravdu chcete vyloučit entity ze zpracování?',
    }).subscribe(doExclude => {
      if (doExclude) {
        this.apiStorageUnitFillerService.storageUnitFillerBulkWithdrawEntities({
          id: this.storageUnitFiller.id,
          body: selection.map((s => s.id!))
        }).subscribe(_ => {
          this.operationCompleted.emit();
        });
      }
    });
  }

}
