import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {
  formatAsLocalIsoDate,
  IczDateValidators,
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidators,
  TextLength
} from '@icz/angular-form-elements';
import {ApiFileTypeService, FileTypeDto} from '|api/codebook';
import {
  DocumentForm,
  EmpowermentDto,
  EntityType,
  FileForm,
  FileHandlingType,
  FileReferenceCreationType, NodeType
} from '|api/commons';
import {FileAllowableObjectClass, OwnDocumentDto, ReceivedDocumentDto} from '|api/document';
import {
  CodebookService,
  ConfigPropValueService,
  DisposalTipDetailLevel,
  enumToOptions,
  FileConfigKeys,
  getCustomFieldsFormArray,
} from '|shared';

enum FileHandlingTypeMode {
  PRIOR_ORDER = 'PRIOR_ORDER',
  COLLECTION_FILE = 'COLLECTION_FILE',
  BOTH = 'BOTH',
}

export function getNewFileForm() {
  return new IczFormGroup({
    description: new IczFormControl<Nullable<string>>(null, []),
    fileForm: new IczFormControl<Nullable<FileForm>>(null, [IczValidators.required()]),
    fileHandlingType: new IczFormControl<Nullable<FileHandlingType>>(null, [IczValidators.required()]),
    fileTypeId: new IczFormControl<Nullable<number>>(null, []),
    initialDocumentId: new IczFormControl<Nullable<number>>(null, []),
    label: new IczFormControl<Nullable<string>>(null, []),
    note: new IczFormControl<Nullable<string>>(null, []),
    relatedEvidence: new IczFormControl<Nullable<string>>(null, []),
    objectClass: new IczFormControl<Nullable<FileAllowableObjectClass>>(null, []),
    physicalLocation: new IczFormControl<Nullable<string>>(null, []),
    keywordIds: new IczFormControl<Nullable<number[]>>(null, []),
    fileReferenceCreationType: new IczFormControl<Nullable<FileReferenceCreationType>>(null, [IczValidators.required()]),
    resolutionDate: new IczFormControl<Nullable<string>>(null),
    securityCategoryId: new IczFormControl<Nullable<number>>(null, []),
    subject: new IczFormControl<Nullable<string>>(null, [IczValidators.maxLength(100), IczValidators.required()], undefined, TextLength.UNLIMITED),
    entityClassId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    customMarker: new IczFormControl<Nullable<string>>(null),
    empowerment : new IczFormControl<Nullable<EmpowermentDto>>(null),
    yearOfRetentionPeriodStart: new IczFormControl<Nullable<number>>(null),
    triggerEventCheckYear: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    customFieldValues: getCustomFieldsFormArray(),
  });
}


@Component({
  selector: 'icz-new-file-form',
  templateUrl: './new-file-form.component.html',
  styleUrls: ['./new-file-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewFileFormComponent implements OnInit, IczOnChanges {

  private codebookService = inject(CodebookService);
  private configService = inject(ConfigPropValueService);
  private destroyRef = inject(DestroyRef);
  private apiFileTypeService = inject(ApiFileTypeService);
  protected loadingService = inject(LoadingIndicatorService);

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  initialDocument: Nullable<OwnDocumentDto | ReceivedDocumentDto>;
  @Input({required: true})
  prefillMetadataFromInitialDocument!: boolean;
  @Input()
  isEmptyFileCreate = false;

  fileFormOptions: IczOption[] = [];
  fileHandlingTypeOptions: IczOption[] = [];
  refNumberCreationTypeOptions: IczOption[] = [];

  allowedFileHandlingType!: FileHandlingTypeMode;
  validFileTypes: FileTypeDto[] = [];

  readonly DocumentForm = DocumentForm;
  readonly presentOrFutureDateValidator = IczDateValidators.presentOrFutureDateValidator;
  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;

  get usesCustomMarker() {
    return this.form.get('fileReferenceCreationType')!.value === FileReferenceCreationType.CUSTOM;
  }

  get entityClassId() {
    return this.form.get('entityClassId')!.value;
  }

  get fileTypeId() {
    return this.form.get('fileTypeId')!.value;
  }

  get initialDocumentForm() {
    return this.initialDocument?.documentForm ?? DocumentForm.DIGITAL;
  }

  ngOnInit(): void {
    this.applyFileFormConfig();
    this.fetchOptions();
    this.setUpValueChangeListeners();
    this.prefillFieldsByInitialDocument();
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.initialDocument && this.initialDocument) {
      this.prefillFieldsByInitialDocument();
    }
  }

  private applyFileFormConfig() {
    this.configService.getPropValuesForCurrentOrganization([
      FileConfigKeys.ALLOWED_FILE_HANDLING_TYPES,
      FileConfigKeys.DEFAULT_FILE_REFERENCE_CREATION_TYPE,
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(values => {
      this.allowedFileHandlingType = values[FileConfigKeys.ALLOWED_FILE_HANDLING_TYPES].value as FileHandlingTypeMode;
      const allHandlingTypes = enumToOptions('fileHandlingType', FileHandlingType);
      switch (this.allowedFileHandlingType) {
        case FileHandlingTypeMode.PRIOR_ORDER:
          this.fileHandlingTypeOptions = allHandlingTypes.filter(op => op.value === FileHandlingTypeMode.PRIOR_ORDER);
          break;
        case FileHandlingTypeMode.COLLECTION_FILE:
          this.fileHandlingTypeOptions = allHandlingTypes.filter(op => op.value === FileHandlingTypeMode.COLLECTION_FILE);
          break;
        default:
          this.fileHandlingTypeOptions = allHandlingTypes;
      }
      if (!this.isEmptyFileCreate) {
        this.form.patchValue({
          fileReferenceCreationType: values[FileConfigKeys.DEFAULT_FILE_REFERENCE_CREATION_TYPE].value as FileReferenceCreationType,
        });
      }
    });
  }

  private setUpValueChangeListeners() {
    this.form.get('fileReferenceCreationType')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((fileReferenceCreationType: Nullable<FileReferenceCreationType>) => {
      const customMarkerControl = this.form.get('customMarker')!;

      if (fileReferenceCreationType === FileReferenceCreationType.CUSTOM) {
        customMarkerControl.addValidators(IczValidators.required());
      }
      else {
        customMarkerControl.clearValidators();
      }
      customMarkerControl.updateValueAndValidity();
    });

    this.form.get('fileForm')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(documentForm => {
      switch (documentForm) {
        case FileForm.ANALOG:
          this.form.get('objectClass')!.setValue(FileAllowableObjectClass.FILE);
          break;
        case FileForm.DIGITAL:
        case FileForm.HYBRID:
          this.form.get('objectClass')!.setValue(FileAllowableObjectClass.FILE_DIGITAL);
          break;
      }
    });

    this.form.get('fileTypeId')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(fileTypeId => {
      const selectedType = this.validFileTypes.find(ft => ft.id === fileTypeId);
      if (selectedType) {
        if (!isNil(selectedType.fileHandlingType)) {
          if (this.fileHandlingTypeOptions.some(handlingType => handlingType.value === selectedType.fileHandlingType)) {
            this.form.get('fileHandlingType')!.setValue(selectedType.fileHandlingType);
          }
        }
        const refNumberCreationOption = this.refNumberCreationTypeOptions.find(op => op.value === selectedType.fileReferenceCreationType);
        if (refNumberCreationOption) {
          this.form.get('fileReferenceCreationType')!.setValue(selectedType.fileReferenceCreationType);
        }
        if (!isNil(selectedType.entityClassId)) {
          this.form.get('entityClassId')!.setValue(selectedType.entityClassId);
        }
      }
    });
  }

  private fetchOptions() {
    this.fileFormOptions = enumToOptions('fileForm', FileForm);
    this.loadingService.doLoading(
    this.apiFileTypeService.fileTypeFindAllValidInTimeByType({
      nodeType: NodeType.LEAF,
      date: formatAsLocalIsoDate(new Date().toISOString()),
    }), this).subscribe(entries => {
      this.validFileTypes = entries;
    });
    if (this.isEmptyFileCreate) {
      this.refNumberCreationTypeOptions = enumToOptions('fileReferenceCreationType', FileReferenceCreationType).filter(option => option.value !== FileReferenceCreationType.INITIAL_DOCUMENT);
    } else {
      this.refNumberCreationTypeOptions = enumToOptions('fileReferenceCreationType', FileReferenceCreationType);
    }
  }

  private prefillFieldsByInitialDocument() {
    if (this.prefillMetadataFromInitialDocument && this.initialDocument) {
      this.form.get('initialDocumentId')!.setValue(this.initialDocument.id);
      this.form.get('subject')!.setValue(this.initialDocument.subject);
      this.form.get('description')!.setValue(this.initialDocument.description);
      this.form.get('empowerment')!.setValue(this.initialDocument.empowerment);
      this.form.get('securityCategoryId')!.setValue(this.initialDocument.securityCategoryId);
      this.form.get('resolutionDate')!.setValue(this.initialDocument.resolutionDate);
      this.form.get('fileForm')!.setValue(this.initialDocument.documentForm as unknown as FileForm);
      this.form.get('keywordIds')!.setValue(this.initialDocument.keywordIds);
      // Because document can only have EntityClass.type == DOCUMENT_FILE, it is safe
      //  to do a plain assignment without any further checks because plain files must
      //  also have entity classes with type == DOCUMENT_FILE.
      this.form.get('entityClassId')!.setValue(this.initialDocument.entityClassId);
      this.form.get('fileForm')!.disable();
      this.form.get('yearOfRetentionPeriodStart')!.setValue(this.initialDocument.yearOfRetentionPeriodStart);
    } else {
      this.form.get('objectClass')!.setValue(FileAllowableObjectClass.FILE_DIGITAL);
    }
  }

  protected readonly EntityType = EntityType;
}
