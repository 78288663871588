@if (dataSource) {
  <icz-registry-office-transfer-incidents-table
    tableId="object-detail-transfer-incidents"
    label="Chyby při předání do spisovny"
    noDataLabel="Žádné chyby při předání do spisovny"
    [dataSource]="dataSource"
    [view]="RegistryOfficeTransferIncidentsTableView.OBJECT_INCIDENTS"
    (contextMenuRequested)="toolbar.openContextMenu($event)"
    (selectedRowsChanged)="selectedRows = $event"
  >
    <icz-registry-office-transfer-incident-toolbar-buttons
      tools
      [selectedRows]="selectedRows"
      (operationCompleted)="incidentsMarkedAsResolved()"
    ></icz-registry-office-transfer-incident-toolbar-buttons>
  </icz-registry-office-transfer-incidents-table>
}
