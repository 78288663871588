import {Component, inject, Input} from '@angular/core';
import {IczFormGroup, isNilOrEmptyString} from '@icz/angular-form-elements';
import {TranslateService} from '@ngx-translate/core';
import {DocumentForm} from '|api/commons';

@Component({
  selector: 'icz-essl-component-counts',
  templateUrl: './essl-component-counts.component.html',
  styleUrls: ['./essl-component-counts.component.scss'],
})
export class EsslComponentCountsComponent {

  private translateService = inject(TranslateService);

  @Input({required: true}) form!: IczFormGroup; // expected is an OwnDocument or ReceivedDocument form which have fields for component counts
  @Input() boxed = false;

  readonly DocumentForm = DocumentForm;

  get complexPaperComponentsCounts(): string {
    let composition: string = '';
    const compositionSeparator = ' + ';
    if (!isNilOrEmptyString(this.form.get('mainPaperComponentSheetCount')!.value)) {
      composition += this.form.get('mainPaperComponentSheetCount')!.value;
      if (!isNilOrEmptyString(this.form.get('paperComponentSheetEnclosureCount')!.value)) {
        composition += `${compositionSeparator} ${this.form.get('paperComponentSheetEnclosureCount')!.value}/${this.form.get('paperComponentSheetCount')!.value}`;
      }
      if (!isNilOrEmptyString(this.form.get('paperComponentVolumeEnclosureCount')!.value)) {
        composition += compositionSeparator + this.form.get('paperComponentVolumeEnclosureCount')!.value + ' ' + this.translateService.instant('Sv.');
      }
    }
    return composition;
  }

  get nonPaperAnalogComponentsSum(): string {
    let result = 0;
    if (!isNilOrEmptyString(this.form.get('nonPaperComponentCount')!.value)) {
      result += this.form.get('nonPaperComponentCount')!.value;
    }
    return result.toString(10);
  }

}
