import {ChangeDetectorRef, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  FormAutocompleteComponent,
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidators,
  locateOptionByValue,
  makeDefaultOptionsDefinition,
  OptionsDefinitionFactory
} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {TranslateService} from '@ngx-translate/core';
import {of, pipe} from 'rxjs';
import {debounceTime, finalize, map, switchMap, tap} from 'rxjs/operators';
import {ApiCrossReferenceTemplateService, CrossReferenceTemplateDto} from '|api/codebook';
import {CrossReferenceSpecializationType, CrossReferenceType, EntityType, ObjectClass} from '|api/commons';
import {ApiCrossReferenceService, CrossReferenceCreateDto} from '|api/document';
import {SearchRecordDto, SearchRecordSourceDocumentFileDto} from '|api/elastic';
import {InternalNotificationKey} from '|api/notification';
import {
  CheckUnsavedFormDialogService,
  CrossReferenceSearchService,
  DocumentSearchService,
  DocumentToastService,
  DocumentToastType,
  entityTypeAsDocumentOrFile,
  FileToastService,
  FileToastType,
  getObjectIcon,
  HistoryService,
  IFormGroupCheckable,
  isDocumentEntity,
  namedDtoToOption,
  WITHOUT_FILE_REF_NUMBER,
  WITHOUT_REF_NUMBER
} from '|shared';
import {FilterOperator, SearchParams} from '@icz/angular-table';

export interface CrossReferenceCreateDialogData {
  entityId: number,
  entityType: EntityType
}

export interface CrossReferenceEntity {
  id: number;
  entityType: EntityType;
  objectClass: ObjectClass
  subject: string;
  refNumber: string;
  elasticId: string;
}

enum EntityTypeFilterOptions {
  ALL = 'ALL',
  DOCUMENT = 'DOCUMENT',
  FILE = 'FILE'
}

const searchResultToEntity = pipe(
  map<SearchRecordDto[], CrossReferenceEntity[]>(content => (content ?? []).map((item: SearchRecordDto) => {
    const source = item.source as SearchRecordSourceDocumentFileDto;

    return {
      id: source.id!,
      entityType: source.entityType!,
      objectClass: source.objectClass!,
      subject: source.subject!,
      refNumber: source.refNumber!,
      elasticId: source.elasticId!,
    };
  }))
);

interface EntityForReferenceOptionData {
  entityType: EntityType;
  entityId: number;
}

type EntityForReferenceOption = IczOption<string, EntityForReferenceOptionData>;

@Component({
  selector: 'icz-cross-reference-create-viewer',
  templateUrl: './cross-reference-create-viewer.component.html',
  styleUrls: ['./cross-reference-create-viewer.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class CrossReferenceCreateViewerComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private documentSearchService = inject(DocumentSearchService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private apiCrossReferenceTemplateService = inject(ApiCrossReferenceTemplateService);
  private apiCrossReferenceService = inject(ApiCrossReferenceService);
  private searchService = inject(CrossReferenceSearchService);
  private documentToastService = inject(DocumentToastService);
  private fileToastService = inject(FileToastService);
  private translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);
  protected modalData = injectModalData<CrossReferenceCreateDialogData>();
  protected historyService = inject(HistoryService);
  protected cd = inject(ChangeDetectorRef);

  @ViewChild('entitySelector')
  private entitySelector!: FormAutocompleteComponent;

  form = new IczFormGroup({
    name: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    entitiesReferringTo: new IczFormControl<Nullable<string[]>>([], [IczValidators.required()]),
    entityType: new IczFormControl<Nullable<EntityTypeFilterOptions>>(EntityTypeFilterOptions.ALL,  [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  templateOptions: IczOption[] = [];
  availableTemplates: CrossReferenceTemplateDto[] = [];

  minSearchTermLength = 3;

  recentlyVisitedEntitiesOptions: EntityForReferenceOption[] = [];

  searchedOptions: EntityForReferenceOption[] = [];
  selectedEntities: EntityForReferenceOption[] = [];

  entityTypeOptions: IczOption[] = [
    {label: 'Všechny objekty', value: EntityTypeFilterOptions.ALL},
    {label: 'Dokumenty', value: EntityTypeFilterOptions.DOCUMENT},
    {label: 'Spisy', value: EntityTypeFilterOptions.FILE}
  ];

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.loadingService.doLoading(
      this.apiCrossReferenceTemplateService.crossReferenceTemplateGetByEntityReferringFrom({entityType: this.modalData.entityType}),
      this
    ).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(templates => {
      this.availableTemplates = templates;
      this.templateOptions = templates.map(namedDtoToOption());
    });

    this.form.get('name')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.form.get('entitiesReferringTo')?.setValue([]);
      if (value) {
        this.loadRecentObjects(Number(value));
      } else {
        this.recentlyVisitedEntitiesOptions = [];
      }
    });

    this.form.get('entityType')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) {
        const templateId = this.form.get('name')?.value;
        if (templateId) {
          this.loadRecentObjects(Number(templateId));
        }
      }
    });

    this.form.get('entitiesReferringTo')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(entitiesEIds => {
      if (entitiesEIds) {
        this.selectedEntities = entitiesEIds.map(eId => {
          const selectedEntity = locateOptionByValue(this.selectedEntities, eId);
          const recentEntity = locateOptionByValue(this.recentlyVisitedEntitiesOptions, eId);
          const searchedEntity = locateOptionByValue(this.searchedOptions, eId);
          if (selectedEntity) {
            return selectedEntity;
          } else if (recentEntity) {
            return recentEntity;
          } else {
            return searchedEntity!;
          }
        });
      } else {
        this.selectedEntities = [];
      }
    });
  }

  private objectToObjectSelectorOption(object: any): EntityForReferenceOption {
    return {
      value: object.elasticId!,
      label: `${object.refNumber ?? this.translateService.instant(WITHOUT_REF_NUMBER)} - ${object.subject}`,
      icon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      data: {
        entityId: object.id!,
        entityType: object.entityType!
      },
    };
  }

  loadRecentObjects(templateId: number) {
    let recentlyVisitedObjects: any[] = [];
    const selectedTemplate = this.availableTemplates.find(tmpl => tmpl.id === templateId)!;
    const selectedEntityTypeFilter = this.form.get('entityType')?.value;
    let entityTypes: EntityType[] = [];
    if (selectedEntityTypeFilter === EntityTypeFilterOptions.ALL) {
      entityTypes = selectedTemplate.entityReferringTo;
    } else if (selectedTemplate.entityReferringTo.some(entity => String(entity) === selectedEntityTypeFilter)) {
      entityTypes = selectedEntityTypeFilter === EntityTypeFilterOptions.DOCUMENT ? [EntityType.DOCUMENT] : [EntityType.FILE];
    }

    const objectClasses: ObjectClass[] = [];
    entityTypes.forEach(entityType => {
      if (entityType === EntityType.DOCUMENT) {
        objectClasses.push(ObjectClass.RECEIVED_DOCUMENT, ObjectClass.RECEIVED_DOCUMENT_DIGITAL, ObjectClass.OWN_DOCUMENT, ObjectClass.OWN_DOCUMENT_DIGITAL);
      } else {
        objectClasses.push(ObjectClass.FILE, ObjectClass.FILE_DIGITAL);
      }
    });

    recentlyVisitedObjects = this.historyService.getRecentlyVisitedObjects(
      objectClasses as unknown as ObjectClass[]
    ).map(rvo => ({
      value: rvo.objectId,
      label: rvo.objectName,
      icon: getObjectIcon(rvo.objectClass)!,
    })).toReversed(); // most recently visited object should be at the top

    if (recentlyVisitedObjects.length) {
      const searchParams: SearchParams = {
        filter: [
          {
            fieldName: 'id',
            operator: FilterOperator.inSet,
            value: String(recentlyVisitedObjects.map(recentlyVisitedObjectOption => recentlyVisitedObjectOption.value)),
          },
          {
            fieldName: 'objectClass',
            operator: FilterOperator.inSet,
            value: String(objectClasses),
          },
        ],
        sort: [{fieldName: 'refNumber'}],
        page: 0,
        size: recentlyVisitedObjects.length,
      };

      this.documentSearchService.findDocumentsAndFilesWithPermissions(searchParams).subscribe(foundObjects => {
        const relevantRecentlyVisitedObjects = recentlyVisitedObjects
          .map(recentlyVisitedObjectOption => foundObjects.content.find(object => object.id === recentlyVisitedObjectOption.value))
          .filter(Boolean)
          .slice(0, 5); // Six most recent objects

        this.recentlyVisitedEntitiesOptions = [
          {
            value: '__recentlyVisitedObjects',
            label: 'Naposledy navštívené objekty:',
            isSeparator: true,
          },
          ...(relevantRecentlyVisitedObjects.map(object => this.objectToObjectSelectorOption(object))),
        ];
        this.cd.detectChanges();
      });
    }
  }

  recentlyVisitedEntitySelected(selection: IczOption[]) {
    if (selection[0]) {
      const selectedValues: string[] = this.form.get('entitiesReferringTo')!.value as string[];
      if (selectedValues) {
        if (!selectedValues.includes(selection[0].value as string)) {
          selectedValues.push(selection[0].value! as string);
          this.form.get('entitiesReferringTo')?.setValue(selectedValues);
        }
      } else {
        this.form.get('entitiesReferringTo')?.setValue([selection[0].value as string]);
      }
      this.entitySelector.closeOptions();
    }
  }

  makeEntitiesSearchOptionsDefinition: OptionsDefinitionFactory = (options$, strForSearch) => {
    const defaultDefinition = makeDefaultOptionsDefinition(options$, strForSearch);

    defaultDefinition.searchtermToOptionsOperator = pipe(
      debounceTime(300),
      switchMap(searchTerm => {
        if (searchTerm && searchTerm.length >= this.minSearchTermLength) {
          const entityTypeSearchTerm = this.createEntityTypeSearchTerm();

          if (entityTypeSearchTerm) {
            return this.findEntitiesForReference(searchTerm, entityTypeSearchTerm).pipe(
              map(page => page.content ?? []),
              searchResultToEntity,
              map((entities: CrossReferenceEntity[]) => entities
                .filter(e => !this.isEntityAlreadySelected(e.elasticId!))
                .map(f => ({
                  value: f.elasticId,
                  label: `${f.refNumber ?? this.translateService.instant(WITHOUT_FILE_REF_NUMBER)} - ${f.subject}`,
                  icon: getObjectIcon(f.objectClass)!,
                  data: {
                    entityType: f.entityType,
                    entityId: f.id
                  }
                }))),
            );
          } else {
            return of([]);
          }
        }
        else {
          return of(this.recentlyVisitedEntitiesOptions);
        }
      }),
      tap(options => this.searchedOptions = options as EntityForReferenceOption[]),
    );

    return defaultDefinition;
  };

  private isEntityAlreadySelected(entityId: string){
    const selectedEntities: Nullable<string[]> = this.form.get('entitiesReferringTo')?.value;
    if (isNil(selectedEntities)) {
      return false;
    } else {
      return selectedEntities.includes(entityId);
    }
  }

  private findEntitiesForReference(searchTerm: string, entityTypeSearchTerm: string) {
    const searchParams: SearchParams = {
      filter: [
        {
          fieldName: 'entityType',
          operator: FilterOperator.inSet,
          value: entityTypeSearchTerm,
        },
        {
          fieldName: 'subject',
          operator: FilterOperator.contains,
          value: searchTerm,
        },
        {
          fieldName: 'id',
          operator: FilterOperator.notEquals,
          value: String(this.modalData.entityId),
        }],
      sort: [{fieldName: 'subject'}],
      page: 0,
      size: 20
    };

    return this.searchService.findEntitiesForCrossReference(searchParams);
  }

  private createEntityTypeSearchTerm() {
    const selectedEntityTypeFilter = this.form.get('entityType')?.value;
    const selectedTemplate = this.availableTemplates.find(tmpl => tmpl.id === this.form.get('name')?.value);
    if (selectedTemplate) {
      if (selectedEntityTypeFilter === EntityTypeFilterOptions.ALL) {
        return String(selectedTemplate.entityReferringTo);
      } else if (selectedTemplate.entityReferringTo.some(entity => String(entity) === selectedEntityTypeFilter)) {
        return String([selectedEntityTypeFilter]);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  submit() {
    const selectedEntities: string[] = this.form.get('entitiesReferringTo')!.value!;
    const selectedTemplate = this.availableTemplates.find(tmpl => tmpl.id === this.form.get('name')?.value);

    const requestBody: CrossReferenceCreateDto[] = selectedEntities.map(entityElasticId => ({
      entityReferringFromId: this.modalData.entityId,
      entityReferringFromType: entityTypeAsDocumentOrFile(this.modalData.entityType)!,
      entityReferringToId: locateOptionByValue(this.selectedEntities, entityElasticId)!.data!.entityId,
      entityReferringToType: entityTypeAsDocumentOrFile(locateOptionByValue(this.selectedEntities, entityElasticId)!.data!.entityType)!,
      name: selectedTemplate!.name,
      roleReferringFrom: selectedTemplate!.roleReferringFrom,
      roleReferringTo: selectedTemplate!.roleReferringTo,
      type: CrossReferenceType.WEAK_BY_USER,
      specialization: CrossReferenceSpecializationType.USER_REFERENCE,
    }));
    this.loadingService.doLoading(
      this.apiCrossReferenceService.crossReferenceCreate({
        templateId: selectedTemplate!.id!,
        body: requestBody,
      }),
      this,
    ).pipe(finalize(() => {
      this.form.reset();
      this.modalRef.close(true);
    })).subscribe({
      next: _ => {
        this.selectedEntities.forEach(entity => {
          if (isDocumentEntity(entity.data!.entityType)) {
            this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.CROSS_REFERENCE_DOCUMENT_CREATED, {
              [InternalNotificationKey.DOCUMENT_SUBJECT]: entity.label,
              [InternalNotificationKey.DOCUMENT_ID]: entity.data!.entityId
            });
          } else if (entity.data!.entityType === EntityType.FILE) {
            this.fileToastService.dispatchFileInfoToast(FileToastType.CROSS_REFERENCE_FILE_CREATED, {
              [InternalNotificationKey.FILE_SUBJECT]: entity.label,
              [InternalNotificationKey.FILE_ID]: entity.data!.entityId
            });
          }
        });
      },
      error: _ => {
        this.documentToastService.dispatchSimpleErrorToast(DocumentToastType.CROSS_REFERENCE_CREATE_ERROR);
      }
    });
  }

  cancel() {
    this.form.reset();
    this.modalRef.close(null);
  }

}
