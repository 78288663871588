<form  [formGroup]="form" [waiting]="loadingService.isLoading(this)" class="gap-64 row">
  <div class="col grow">
    <icz-section label="Zaevidování spisu">
      <div class="col">
        <icz-form-autocomplete formControlName="fileHandlingType" label="Způsob vedení spisu" class="grow"
          [options]="fileHandlingTypeOptions"
          [allowSingleOptionPreselection]="true"
        ></icz-form-autocomplete>
        <icz-form-autocomplete formControlName="fileReferenceCreationType" label="Způsob tvorby spisové značky" class="grow"
          [options]="refNumberCreationTypeOptions"
        ></icz-form-autocomplete>
        @if (usesCustomMarker) {
          <icz-form-field formControlName="customMarker" label="Vlastní označení"></icz-form-field>
        }
      </div>
    </icz-section>
    <icz-section label="Základní údaje o spisu">
      <div class="col">
        <icz-form-field formControlName="subject" label="Věc" [autoSizeMax]="3"></icz-form-field>
        <icz-form-field formControlName="description" label="Popis" [autoSizeMax]="6"></icz-form-field>
        <icz-form-field formControlName="note" label="Poznámka"></icz-form-field>
        <icz-form-field formControlName="relatedEvidence" label="Související evidence"></icz-form-field>
        <div class="row grow">
          <icz-form-field formControlName="label" label="Značka" class="grow"></icz-form-field>
          <icz-form-autocomplete formControlName="fileForm" label="Forma" [options]="fileFormOptions" class="grow"></icz-form-autocomplete>
        </div>
      </div>
    </icz-section>
    <icz-section label="Zatřídění">
      <div class="col">
        <icz-file-type-selector [form]="form" controlName="fileTypeId" class="grow"></icz-file-type-selector>
        <icz-entity-class-selector
          [form]="form"
          controlName="entityClassId"
          [showPopupDetails]="true"
        ></icz-entity-class-selector>
        <icz-disposal-schedule-retention-trigger-widget
          [entityClassId]="entityClassId"
          [form]="form"
        ></icz-disposal-schedule-retention-trigger-widget>
      </div>
    </icz-section>
  </div>
  <div class="col grow">
    <icz-section label="Specifické">
      <div class="col">
        <icz-form-autocomplete label="Agendové číslo" testingFeature [options]="[]"></icz-form-autocomplete>
        <icz-keywords-selector [form]="form" controlName="keywordIds" ></icz-keywords-selector>
        <icz-document-file-custom-fields-section
          [form]="form"
          [entityType]="EntityType.FILE"
          [fileTypeId]="fileTypeId"
        ></icz-document-file-custom-fields-section>
      </div>
    </icz-section>
    @if (initialDocumentForm === DocumentForm.ANALOG || initialDocumentForm === DocumentForm.HYBRID) {
      <icz-section label="Uložení spisu">
        <div class="col">
          <icz-form-field formControlName="physicalLocation" class="grow" label="Uložení"></icz-form-field>
        </div>
      </icz-section>
    }
    <icz-section label="Zmocnění a termín vyřízení">
      <div class="row grow">
        <icz-date-picker label="Termín vyřízení" class="grow" [selectableDates]="presentOrFutureDateValidator" formControlName="resolutionDate" outputFormat="dateTime"></icz-date-picker>
      </div>
      <div class="row grow">
        <icz-empowerment formControlName="empowerment"></icz-empowerment>
      </div>
    </icz-section>
  </div>
</form>
