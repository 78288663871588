import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {ObjectClass} from '|api/commons';
import {
  AbstractObjectDetailService,
  createAbsoluteRoute,
  EsslObjectDetailEntity,
  getLockedStateText,
  HistoryService,
  ObjectDetailSubmitActionType,
} from '|shared';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Observable} from 'rxjs';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {LoadingIndicatorService, TabItemWithPriority} from '@icz/angular-essentials';

@Component({
  selector: 'icz-essl-object-detail',
  templateUrl: './essl-object-detail.component.html',
  styleUrls: ['./essl-object-detail.component.scss'],
})
export class EsslObjectDetailComponent<TObject extends EsslObjectDetailEntity> implements OnInit {

  private router = inject(Router);
  private route = inject(ActivatedRoute);
  protected abstractObjectDetailService = inject(AbstractObjectDetailService);
  protected loadingService = inject(LoadingIndicatorService);
  private historyService = inject(HistoryService);
  private destroyRef = inject(DestroyRef);
  protected translateService = inject(TranslateService);
  protected translateParser = inject(TranslateParser);

  @Input({required: true})
  objectNameGeneratorFn!: (object: TObject) => string[];
  @Input({required: true})
  baseUrlPrefixParts!: string[];

  tabs: TabItemWithPriority[] = [];

  baseUrl$!: Observable<string>;
  queryParams$!: Observable<Params>;

  private submitType: Nullable<ObjectDetailSubmitActionType>;

  get showSubmitBar(): boolean {
    return this.abstractObjectDetailService.showSubmitBar && (this.abstractObjectDetailService.form$.value?.dirty || this.abstractObjectDetailService.alreadySaved);
  }

  get saveButtonLabel(): string {
    return this.loadingService.isLoading(this) && this.submitType === ObjectDetailSubmitActionType.SAVE
      ? 'Ukládám'
      : this.abstractObjectDetailService.form$.value?.pristine && this.abstractObjectDetailService.alreadySaved
      ? 'Změny uloženy'
      : 'Uložit změny';
  }

  get saveAndCloseButtonLabel(): string {
    return this.loadingService.isLoading(this) && this.submitType === ObjectDetailSubmitActionType.SAVE_AND_CLOSE
      ? 'Ukládám'
      : 'Uložit a zavřít';
  }

  get isSubmitDisabled() {
    return this.abstractObjectDetailService.alreadySaved && !this.abstractObjectDetailService.form$.value?.dirty;
  }

  readonly ObjectDetailSubmitActionType = ObjectDetailSubmitActionType;

  getLockedStateText() {
    return getLockedStateText(this.abstractObjectDetailService.object, this.translateService, this.translateParser);
  }

  ngOnInit() {
    this.abstractObjectDetailService.tabs$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(tabs => {
      const url = this.router.url;
      this.tabs = tabs;
      this.checkTabPermissions(url);
    });

    this.abstractObjectDetailService.submit$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(
      submitActionType => this.submitType = submitActionType
    );

    this.abstractObjectDetailService.submitFinished$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(
      () => this.submitType = null
    );

    this.queryParams$ = this.route.queryParams;

    this.baseUrl$ = this.route.params.pipe(
      map(params => params?.uuid),
      map(uuid => createAbsoluteRoute(...this.baseUrlPrefixParts, uuid))
    );

    this.abstractObjectDetailService.object$.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(object => {
      const urlParts = this.router.url.split('/');
      const aliasParts = this.objectNameGeneratorFn(object as TObject);

      urlParts[urlParts.length - 1] = 'preview';

      this.historyService.setHistoryBitAlias(
        aliasParts.join(' '),
        urlParts.join('/'),
        {
          objectClass: object.objectClass as unknown as ObjectClass,
          objectId: object.id!,
          objectName: aliasParts.join(' '),
        },
        aliasParts
      );
    });
  }

  private checkTabPermissions(url: string) {
    const routerUrlParts = url.split('/');
    const routeTabId = routerUrlParts[routerUrlParts.length - 1];

    const routeTab = this.tabs.find(t => t.id === routeTabId);

    if (routeTab?.disabled) {
      this.router.navigate(
        ['preview'],
        {
          relativeTo: this.route,
        }
      );
    }
  }

}
