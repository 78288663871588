import {inject, Injectable} from '@angular/core';
import {IczModalService, IczModalSizeClass} from '@icz/angular-modal';
import {
  CrossReferenceCreateDialogData,
  CrossReferenceCreateViewerComponent
} from '../components/cross-reference-create-viewer/cross-reference-create-viewer.component';
import {
  CrossReferenceDeleteDialogData,
  CrossReferenceDeleteViewerComponent
} from '../components/cross-reference-delete-viewer/cross-reference-delete-viewer.component';
import {FileDto} from '|api/document';
import {
  StrongCrossReferenceCreateDialogComponent
} from '../components/strong-cross-reference-create-dialog/strong-cross-reference-create-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CrossReferenceModalService {

  private modalService = inject(IczModalService);

  openCrossReferenceCreateDialog(viewerData: CrossReferenceCreateDialogData) {
    return this.modalService.openComponentInModal<boolean, CrossReferenceCreateDialogData>({
      component: CrossReferenceCreateViewerComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: 'Přidat křížový odkaz na související objekt',
      },
      data: viewerData,
    });
  }

  openCrossReferenceDeleteDialog(viewerData: CrossReferenceDeleteDialogData) {
    return this.modalService.openComponentInModal<boolean, CrossReferenceDeleteDialogData>({
      component: CrossReferenceDeleteViewerComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: 'Odstranění vazby mezi objekty',
      },
      data: viewerData,
    });
  }

  openStrongCrossReferenceCreateDialog(viewerData: FileDto) {
    return this.modalService.openComponentInModal<boolean, FileDto>({
      component: StrongCrossReferenceCreateDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_RESPONSIVE,
        titleTemplate: 'Přidat pevnou vazbu na spis',
      },
      data: viewerData,
    });
  }

}
