import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  ColumnDefinition,
  extendDefaultTableConfig,
  FilterType,
  IczTableDataSource,
  TableColumnsData,
  TableComponent,
  TableConfig
} from '@icz/angular-table';
import {StorageUnitDto} from '|api/document';
import {
  ApplicationRoute,
  createAbsoluteRoute,
  documentColumnNames,
  DocumentFiltersDataService,
  DocumentsRoute,
  DocumentsTableColumn,
  getDocumentsColumnsData,
  StorageUnitView
} from '|shared';
import {TemplatePoolService} from '@icz/angular-essentials';
import {Router} from '@angular/router';

export enum StorageUnitTableColumn {
  SELECTION = 'selection',
  NAME = 'name',
  NUMBER = 'storageUnitNumber',
  CONTENT_BLOCKED = 'contentBlocked',
  FORM = 'form',
  CONTENT_COUNT = 'contentCount',
  STORAGE_UNIT_FILLER_ID = 'storageUnitFillerId',
}

export const storageUnitColumnNames: Record<StorageUnitTableColumn, string> = {
  [StorageUnitTableColumn.SELECTION]: 'Výběr',
  [StorageUnitTableColumn.NUMBER]: 'Číslo ukládací jednotky',
  [StorageUnitTableColumn.NAME]: 'Název ukládací jednotky',
  [StorageUnitTableColumn.CONTENT_BLOCKED]: 'Blokován obsah',
  [StorageUnitTableColumn.FORM]: 'Forma',
  [StorageUnitTableColumn.CONTENT_COUNT]: 'Blokován obsah',
  [StorageUnitTableColumn.STORAGE_UNIT_FILLER_ID]: 'ID dávky',
};

// Storage units share many columns including filter and sort behavior with documents, so we're borrowing'
//  document columndefs
enum ColumnDefinitionSource {
  DOCUMENTS = 'DOCUMENTS',
  STORAGE_UNITS = 'STORAGE_UNITS',
}

interface StorageUnitColumnSet {
  name: DocumentsTableColumn | StorageUnitTableColumn,
  source: ColumnDefinitionSource
}

const storageUnitInsertColumnSet: StorageUnitColumnSet[] = [
  {name: DocumentsTableColumn.OBJECT_CLASS, source: ColumnDefinitionSource.DOCUMENTS},
  {name: DocumentsTableColumn.IDENTIFIER, source: ColumnDefinitionSource.DOCUMENTS},
  {name: StorageUnitTableColumn.NUMBER, source: ColumnDefinitionSource.STORAGE_UNITS},
  {name: StorageUnitTableColumn.CONTENT_BLOCKED, source: ColumnDefinitionSource.STORAGE_UNITS},
  {name: StorageUnitTableColumn.NAME, source: ColumnDefinitionSource.STORAGE_UNITS},
  {name: StorageUnitTableColumn.FORM, source: ColumnDefinitionSource.STORAGE_UNITS},
  {name: StorageUnitTableColumn.CONTENT_COUNT, source: ColumnDefinitionSource.STORAGE_UNITS},
  {name: DocumentsTableColumn.CREATED_AT, source: ColumnDefinitionSource.DOCUMENTS},
  {name: DocumentsTableColumn.PHYSICAL_LOCATION, source: ColumnDefinitionSource.DOCUMENTS},
  {name: DocumentsTableColumn.EXPECTED_DISPOSAL_YEAR, source: ColumnDefinitionSource.DOCUMENTS},
  {name: DocumentsTableColumn.DISPOSAL_SCHEDULE_ID, source: ColumnDefinitionSource.DOCUMENTS},
  {name: DocumentsTableColumn.MODIFIED_AT, source: ColumnDefinitionSource.DOCUMENTS},
  {name: DocumentsTableColumn.ENTITY_CLASS_ID, source: ColumnDefinitionSource.DOCUMENTS},
  {name: DocumentsTableColumn.DISPOSAL_YEAR, source: ColumnDefinitionSource.DOCUMENTS},
  {name: DocumentsTableColumn.EXPECTED_CONTENT_DISPOSAL_YEAR, source: ColumnDefinitionSource.DOCUMENTS},
];

const storageUnitColumnSet: StorageUnitColumnSet[] = [
  {name: StorageUnitTableColumn.SELECTION, source: ColumnDefinitionSource.STORAGE_UNITS},
  ...storageUnitInsertColumnSet,
  {name: StorageUnitTableColumn.STORAGE_UNIT_FILLER_ID, source: ColumnDefinitionSource.STORAGE_UNITS},
];

function getStorageUnitColumnsData(columns: StorageUnitTableColumn[]): ColumnDefinition<StorageUnitTableColumn>[] {
  const columnDefinitions: ColumnDefinition<StorageUnitTableColumn>[] = [];

  columns.forEach(column => {
    let columnDefinition: ColumnDefinition<StorageUnitTableColumn>;
    switch (column) {
      case StorageUnitTableColumn.SELECTION:
        columnDefinition = {
          id: column,
          displayed: true,
          allowSettingInContextMenu: false,
          label: documentColumnNames[column],
          filterType: FilterType.NONE
        };
        break;

      case StorageUnitTableColumn.NUMBER:
        columnDefinition = {
          id: column,
          label: storageUnitColumnNames[column],
          filterType: FilterType.TEXT,
          toggleable: false,
        };
        break;
      case StorageUnitTableColumn.NAME:
        columnDefinition = {
          id: column,
          label: storageUnitColumnNames[column],
          filterType: FilterType.TEXT,
        };
        break;
      case StorageUnitTableColumn.CONTENT_BLOCKED:
        columnDefinition = {
          id: column,
          icon: 'icon_lock',
          fixedWidth: 60,
          label: storageUnitColumnNames[column],
          filterType: FilterType.BOOLEAN,
        };
        break;
      case StorageUnitTableColumn.STORAGE_UNIT_FILLER_ID:
        columnDefinition = {
          id: column,
          label: storageUnitColumnNames[column],
          filterType: FilterType.NUMBER,
          displayed: false,
        };
        break;
      default:
        return;
    }

    columnDefinitions.push(columnDefinition);
  });

  return columnDefinitions;
}

function getStaticStorageUnitsColumnsForView(viewType: StorageUnitView): StorageUnitColumnSet[] {
  switch (viewType) {
    case StorageUnitView.STORAGE_UNITS_TABLE:
      return storageUnitColumnSet;
    case StorageUnitView.STORAGE_UNIT_FILLER_CONTENT_STORAGE_UNITS:
      return storageUnitInsertColumnSet;
    case StorageUnitView.STORAGE_UNIT_SELECT:
      return storageUnitInsertColumnSet;
    default:
      return storageUnitColumnSet;
  }
}

@Component({
  selector: 'icz-storage-units-table',
  templateUrl: './storage-units.component.html',
  styleUrls: ['./storage-units.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageUnitsComponent implements OnInit {
  @Input({required: true}) tableId!: string;
  @Input({required: true}) viewType!: StorageUnitView;
  @Input({required: true}) dataSource!: IczTableDataSource<StorageUnitDto>;
  @Output() activeRowChanged = new EventEmitter<Nullable<StorageUnitDto>>();

  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private templatePool = inject(TemplatePoolService);
  private router = inject(Router);

  @ViewChild('iczTable') iczTable!: TableComponent<DocumentsTableColumn | StorageUnitTableColumn>;

  selectedRows: Array<StorageUnitDto> = [];

  unselectAndReload() {
    this.dataSource.reload(true);
  }

  setActiveRow(row: StorageUnitDto) {
    this.activeRowChanged.emit(row);
  }

  columnsData!: TableColumnsData<string>;
  config!: TableConfig<DocumentsTableColumn | StorageUnitTableColumn>;

  getStorageUnitBlockedIcon(row: StorageUnitDto) {
    if (row.contentBlocked) {
      return 'icon_lock';
    } else {
      return 'icon_unlocked';
    }
  }

  openStorageUnitDetail(row: StorageUnitDto) {
    this.iczTable.setFocusedRow(row);
    this.router.navigateByUrl(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      DocumentsRoute.STORAGE_UNIT,
      row.id,
    ));
  }

  private initializeTableConfig() {
    let defaultFilterColumns: (DocumentsTableColumn | StorageUnitTableColumn)[] = [];
    let autoOpenFilter = false;
    let showReload = true;
    let showColumnSelector = true;
    let showFilter = true;
    let showTools = true;

    if (this.viewType === StorageUnitView.STORAGE_UNIT_SELECT) {
      autoOpenFilter = true;
      showReload = false;
      showFilter = false;
      showTools = false;
      showColumnSelector = false;
      defaultFilterColumns = [
        DocumentsTableColumn.IDENTIFIER,
        StorageUnitTableColumn.NUMBER,
        StorageUnitTableColumn.NAME,
      ];
    }
    else if (this.viewType === StorageUnitView.STORAGE_UNITS_TABLE) {
      defaultFilterColumns = [
        DocumentsTableColumn.OBJECT_CLASS,
        StorageUnitTableColumn.CONTENT_BLOCKED,
        StorageUnitTableColumn.NAME,
        DocumentsTableColumn.CREATED_AT,
        DocumentsTableColumn.CONTENT_COUNT,
        DocumentsTableColumn.EXPECTED_DISPOSAL_YEAR,
        DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
      ];
    }
    else if (this.viewType === StorageUnitView.STORAGE_UNIT_FILLER_CONTENT_STORAGE_UNITS) {
      showTools = false;
      defaultFilterColumns = [
        DocumentsTableColumn.OBJECT_CLASS,
        StorageUnitTableColumn.CONTENT_BLOCKED,
        DocumentsTableColumn.STORAGE_UNIT_NAME,
        DocumentsTableColumn.CREATED_AT,
        DocumentsTableColumn.CONTENT_COUNT,
        DocumentsTableColumn.EXPECTED_DISPOSAL_YEAR,
        DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
      ];
    }
    this.config = extendDefaultTableConfig<DocumentsTableColumn | StorageUnitTableColumn>({
      defaultFilterColumns,
      toolbarConfig: {
        showFilter,
        showSavedFilters: true,
        autoOpenFilter,
        showReload,
        showColumnSelector,
        showTools,
      },
    });
  }

  ngOnInit() {
    const documentColumns = getDocumentsColumnsData(
      this.viewType,
      this.documentFiltersDataService,
      this.templatePool,
      false,
      getStaticStorageUnitsColumnsForView(this.viewType)
        .filter(c => c.source === ColumnDefinitionSource.DOCUMENTS)
        .map(c => c.name) as DocumentsTableColumn[]
    );

    const storageUnitColumns = getStorageUnitColumnsData(
      getStaticStorageUnitsColumnsForView(this.viewType)
        .filter(c => c.source === ColumnDefinitionSource.STORAGE_UNITS)
        .map(c => c.name) as StorageUnitTableColumn[]
    );

    this.columnsData = new TableColumnsData<string>([...storageUnitColumns, ...documentColumns]);

    this.initializeTableConfig();
  }

  protected readonly StorageUnitTableColumn = StorageUnitTableColumn;
  protected readonly StorageUnitView = StorageUnitView;
  protected readonly DocumentsTableColumn = DocumentsTableColumn;
}
