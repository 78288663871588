import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {
  AbstractObjectDetailService,
  DocumentDetailRoute,
  GenericSearchService,
  IczSimpleTableDataSource,
  isDocumentObject,
  load,
  RegistryOfficeTransferIncidentsTableView,
  unwrapSearchContent
} from '|shared';
import {RegistryOfficeTransferIncidentDto} from '|api/document';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ApiElasticsearchService} from '|api/elastic';
import {EntityType} from '|api/commons';
import {
  RegistryOfficeTransferIncidentToolbarButtonsComponent
} from '../../../../../../../shared/src/lib/components/shared-business-components/registry-office-transfer-incidents-table/registry-office-transfer-incident-toolbar-buttons/registry-office-transfer-incident-toolbar-buttons.component';

const INCIDENTS_COUNTER_ID = DocumentDetailRoute.INCIDENTS as string;

@Component({
  selector: 'icz-object-detail-transfer-incidents',
  templateUrl: './object-detail-transfer-incidents.component.html',
  styleUrls: ['./object-detail-transfer-incidents.component.scss'],
})
export class ObjectDetailTransferIncidentsComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private genericSearchService = inject(GenericSearchService);
  private abstractObjectDetailService = inject(AbstractObjectDetailService);

  @ViewChild(RegistryOfficeTransferIncidentToolbarButtonsComponent)
  toolbar!: RegistryOfficeTransferIncidentToolbarButtonsComponent;

  selectedRows: RegistryOfficeTransferIncidentDto[] = [];

  readonly RegistryOfficeTransferIncidentsTableView = RegistryOfficeTransferIncidentsTableView;

  dataSource!: IczSimpleTableDataSource<RegistryOfficeTransferIncidentDto>;

  ngOnInit() {
    this.abstractObjectDetailService.object$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.dataSource = new IczSimpleTableDataSource<RegistryOfficeTransferIncidentDto>(
        this.genericSearchService,
        {
          url: ApiElasticsearchService.ElasticsearchFindRegistryOfficeTransferIncidentsForEntityPath,
          staticPathParams: {
            entityType: isDocumentObject(this.abstractObjectDetailService.object) ? EntityType.DOCUMENT : this.abstractObjectDetailService.object.entityType!,
            entityId: String(this.abstractObjectDetailService.object.id),
          },
          customMapper: unwrapSearchContent(),
        }
      );
    });
  }

  incidentsMarkedAsResolved() {
    this.dataSource.reload(true);
    this.abstractObjectDetailService.reloadObject({
      [INCIDENTS_COUNTER_ID]: load(),
    });
  }

}
