import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {
  AbstractObjectDetailService,
  CodebookService,
  enumToOptions,
  isDocumentObject,
  isFileObject,
  isStorageUnitObject,
  namedDtoToOption,
} from '|shared';
import {RegistryOfficeTransferState} from '|api/commons';
import {
  ApiFileService,
  ApiStorageUnitService,
  FileDto,
  RegistryOfficeTransferWithMainEntityInfoDto,
  StorageUnitDto
} from '|api/document';
import {forkJoin, Observable, of} from 'rxjs';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {IczOption} from '@icz/angular-form-elements';


@Component({
  selector: 'icz-object-detail-preview-transfer-info-section',
  templateUrl: './object-detail-preview-transfer-info-section.component.html',
  styleUrls: ['./object-detail-preview-transfer-info-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectDetailPreviewTransferInfoSectionComponent implements IczOnChanges {

  private codebookService = inject(CodebookService);
  private apiFileService = inject(ApiFileService);
  private apiStorageUnitService = inject(ApiStorageUnitService);
  private abstractObjectDetailService = inject(AbstractObjectDetailService);
  protected loadingService = inject(LoadingIndicatorService);

  @Input({ required: true })
  transferWithMainEntity!: RegistryOfficeTransferWithMainEntityInfoDto;

  registryOfficeOptions: IczOption[] = [];

  mainTransferEntity: Nullable<FileDto|StorageUnitDto>;

  registryOfficeTransferStateOptions = enumToOptions('registryOfficeTransferState', RegistryOfficeTransferState);

  get isOnMainRegistryOfficeTransferEntityDetail() {
    if (isDocumentObject(this.abstractObjectDetailService.object)) {
      return this.abstractObjectDetailService.objectId === this.transferWithMainEntity.mainTransferEntityDocumentId;
    }
    else if (isFileObject(this.abstractObjectDetailService.object)) {
      return this.abstractObjectDetailService.objectId === this.transferWithMainEntity.mainTransferEntityFileId;
    }
    else if (isStorageUnitObject(this.abstractObjectDetailService.object)) {
      return this.abstractObjectDetailService.objectId === this.transferWithMainEntity.mainTransferEntityStorageUnitId;
    }
    else {
      return false;
    }
  }

  get isHandedOverInsideFileOrStorageUnit() {
    return this.transferWithMainEntity?.mainTransferEntityFileId || this.transferWithMainEntity?.mainTransferEntityStorageUnitId;
  }

  get isMainTransferEntitySectionVisible() {
    return this.isHandedOverInsideFileOrStorageUnit && !this.isOnMainRegistryOfficeTransferEntityDetail;
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.transferWithMainEntity && this.transferWithMainEntity) {
      let mainTransferEntity$: Nullable<Observable<FileDto|StorageUnitDto>>;

      if (this.isMainTransferEntitySectionVisible) {
        if (this.transferWithMainEntity.mainTransferEntityFileId) {
          mainTransferEntity$ = this.apiFileService.fileFindFileWithObjectExistenceCheck({id: this.transferWithMainEntity.mainTransferEntityFileId});
        }
        else if (this.transferWithMainEntity.mainTransferEntityStorageUnitId) {
          mainTransferEntity$ = this.apiStorageUnitService.storageUnitFindById({id: this.transferWithMainEntity.mainTransferEntityStorageUnitId});
        }
      }

      this.loadingService.doLoading(
        forkJoin([
          mainTransferEntity$ ?? of(null),
          this.codebookService.registryOffices(),
        ]),
        this
      ).subscribe(([mainTransferEntity, registryOffices]) => {
        this.mainTransferEntity = mainTransferEntity;
        this.registryOfficeOptions = registryOffices.map(namedDtoToOption());
      });
    }
  }

}
