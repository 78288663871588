import {FilterOperator, IczTableDataSource} from '@icz/angular-table';
import {StorageUnitFillerContentDto} from '|api/document';
import {StorageUnitFillerEntitiesSearchService} from '|shared';

export class StorageUnitFillerEntitiesDatasource extends IczTableDataSource<StorageUnitFillerContentDto> {
  constructor(storageUnitFillerEntitiesSearchService: StorageUnitFillerEntitiesSearchService, id: number) {
    super(searchParams => {
      const errorFilter = searchParams.filter.find(f => f.fieldName === 'error');
      if (errorFilter) {
        if (errorFilter.operator === 'eq') {
          if (errorFilter.value === 'true') {
            errorFilter.not = true;
            errorFilter.operator = FilterOperator.empty;
            errorFilter.value = '';
          } else {
            errorFilter.not = false;
            errorFilter.operator = FilterOperator.empty;
            errorFilter.value = '';
          }
        }
      }

      return storageUnitFillerEntitiesSearchService.findStorageUnitFillerEntities(searchParams, id);
    });
  }
}
