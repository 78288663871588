import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {ApplicationConfigService, CodebookService, DisposalTipDetailLevel, enumToOptions,} from '|shared';
import {EntityClassDto} from '|api/codebook';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RetentionTriggerTypeCode, StorageUnitForm} from '|api/commons';
import {IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {STORAGE_UNIT_DISPOSAL_YEAR_VALIDATORS} from '../storage-unit.forms';

export function getStorageUnitSpecificationForm() {
  return new IczFormGroup({
    description: new IczFormControl<Nullable<string>>(null),
    disposalScheduleId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    disposalYear: new IczFormControl<Nullable<number>>(null, STORAGE_UNIT_DISPOSAL_YEAR_VALIDATORS),
    enforceStrictDisposalSchedule: new IczFormControl<Nullable<boolean>>(null, [IczValidators.required()]),
    enforceStrictDisposalYear: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
    enforceStrictEntityClass: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
    entityClassId: new IczFormControl<Nullable<number>>(null),
    externalRetentionTriggerId: new IczFormControl<Nullable<number>>(null),
    name: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    physicalLocation: new IczFormControl<Nullable<string>>(null),
    retentionTriggerTypeCode: new IczFormControl<Nullable<RetentionTriggerTypeCode>>(null),
    retentionTriggerYear: new IczFormControl<Nullable<number>>(null),
    storageUnitForm: new IczFormControl<Nullable<StorageUnitForm>>(null, [IczValidators.required()]),
    storageUnitNumber: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });
}

@Component({
  selector: 'icz-new-storage-unit-specification-form',
  templateUrl: './new-storage-unit-specification-form.component.html',
  styleUrls: ['./new-storage-unit-specification-form.component.scss']
})
export class NewStorageUnitSpecificationFormComponent implements OnInit {

  applicationConfigService = inject(ApplicationConfigService);
  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  form!: IczFormGroup;

  entityClasses: EntityClassDto[] = [];

  storageUnitFormOptions = enumToOptions('storageUnitForm', StorageUnitForm);
  disposalScheduleEnforcementOptions: IczOption<boolean>[] = [
    {
      value: true,
      label: 'Ano - jen pro shodný skartační režim',
    },
    {
      value: false,
      label: 'Ne - lze vložit i objekt s méně přísnou skartační operací',
    }
  ];

  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;

  ngOnInit() {
    this.codebookService.entityClasses().subscribe(entityClasses => {
      this.entityClasses = entityClasses;

      const disposalScheduleControl = this.form.get('disposalScheduleId')!;

      this.form.get('entityClassId')!.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(entityClassId => {
        const entityClass = this.entityClasses.find(ec => ec.id === entityClassId);
        const applicableDisposalScheduleId = entityClass?.disposalScheduleId;

        if (applicableDisposalScheduleId) {
          disposalScheduleControl.setValue(applicableDisposalScheduleId);
          disposalScheduleControl.disable();
        }
        else {
          disposalScheduleControl.setValue(null);
          disposalScheduleControl.enable();
        }
      });
    });
  }

}
