import {DocumentState} from '|api/commons';
import {DocumentSearchService, DocumentsTableColumn} from '|shared';
import {SearchRecordSourceDocumentFileDto, SettlementDto} from '|api/elastic';
import {FilterOperator, IczTableDataSource, Page} from '@icz/angular-table';
import {FileDto} from '|api/document';
import {map, pipe} from 'rxjs';

export interface SearchRecordSourceDocumentDtoWithFileRoles extends SearchRecordSourceDocumentFileDto {
  isInitialDocument: boolean;
  isSettlingDocument: boolean;
}


function enrichDocumentsWithFileRoles(file: FileDto, settlement: Nullable<SettlementDto>) {
  return pipe(
    map<Page<SearchRecordSourceDocumentFileDto>, Page<SearchRecordSourceDocumentDtoWithFileRoles>>(page => ({
      ...page,
      content: page.content.map(document => ({
        ...document,
        isInitialDocument: file.initialDocumentId === document.id,
        isSettlingDocument: settlement?.relatedDocumentId === document.id,
      }))
    }))
  );
}

export class FileDetailContentDatasource extends IczTableDataSource<SearchRecordSourceDocumentDtoWithFileRoles> {
  constructor(searchService: DocumentSearchService, file: FileDto, settlement: Nullable<SettlementDto>, isObjectExistenceCheckMode: boolean, documentStates?: DocumentState[]) {
    super(
      searchParams => {
        const insertedDocumentsFilter = searchParams.filter.find(f => f.fieldName === DocumentsTableColumn.FILE_INSERTED_DOCUMENT);

        if (insertedDocumentsFilter) {
          const filterItemIndex = searchParams.filter.findIndex(f => f.fieldName === DocumentsTableColumn.FILE_INSERTED_DOCUMENT);

          if (insertedDocumentsFilter.value === 'true') {
            searchParams.filter[filterItemIndex].value = null;
            searchParams.filter[filterItemIndex].operator = FilterOperator.notEmpty;
          }
          else {
            searchParams.filter = searchParams.filter.toSpliced(filterItemIndex, 1);
          }
        }

        if (documentStates?.length) {
          searchParams.filter = [...searchParams.filter, {fieldName: 'documentState', value: documentStates.join(',')}];
        }

        if (isObjectExistenceCheckMode) {
          return searchService.findAllDocumentsInFileWithObjectExistenceCheck(searchParams, file.id).pipe(
            enrichDocumentsWithFileRoles(file, settlement),
          );
        }
        else {
          return searchService.findAllDocumentsInFile(searchParams, file.id).pipe(
            enrichDocumentsWithFileRoles(file, settlement),
          );
        }
      },
    );
  }
}
