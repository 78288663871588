<icz-section boxed label="Spisovna" [waiting]="loadingService.isLoading$(this) | async">
  @if (transferWithMainEntity?.registryOfficeTransfer; as transfer) {
    <icz-readonly-field label="Spisovna" [options]="registryOfficeOptions" [value]="transfer.registryOfficeId"></icz-readonly-field>
    <div class="row">
      <icz-readonly-field class="grow" label="Předáno spisovně" [value]="transfer.handedOverAt | localizedDate"></icz-readonly-field>
      <icz-readonly-field class="grow" label="Předáno k uložení" [value]="transfer.takenOverAt | localizedDate"></icz-readonly-field>
    </div>
    <icz-readonly-field label="Předal" [value]="transfer.handedOverByFunctionalPositionId | findFunctionalPositionById | async"></icz-readonly-field>
    <div class="row">
      <icz-readonly-field class="grow" label="Stav uložení" [options]="registryOfficeTransferStateOptions" [value]="transfer.registryOfficeTransferState"></icz-readonly-field>
      <icz-readonly-field class="grow" label="Uloženo ve spisovně" [value]="transfer.acceptedAt | localizedDate"></icz-readonly-field>
    </div>
    @if (isMainTransferEntitySectionVisible) {
      <div>
        <icz-label class="icz-body-1 mb-8" label="Ukládání jako součást"></icz-label>
        <icz-entity-info
          class="inline-block"
          [entity]="mainTransferEntity!"
          [isLink]="true"
        ></icz-entity-info>
      </div>
    }
  }
</icz-section>
