import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {Observable, of} from 'rxjs';
import {
  AbstractToolbarButtonsComponent, AuthorizedButton,
  AuthorizedButtonService,
  DocumentDetailCountType,
  DocumentDetailService,
  entityTypeAsDocumentOrFile,
  FileDetailCountType,
  FileDetailService,
  increment,
  isFileEntity,
  load,
  TOOLBAR_BUTTONS_COMPONENT_STYLES,
  TOOLBAR_BUTTONS_COMPONENT_TEMPLATE,
  CommonToolbarButtonValidators, ObjectDetailPart
} from '|shared';
import {CrossReferenceWithDetailDto, DocumentDto, FileDto} from '|api/document';
import {AuthorizedEntityType, DocumentAuthorizedOperation, FileAuthorizedOperation} from '|api/core';
import {EntityType} from '|api/commons';

import {
  CrossReferenceModalService
} from '../../../services/cross-reference-modal.service';

@Component({
  selector: 'icz-cross-reference-toolbar-buttons',
  template: TOOLBAR_BUTTONS_COMPONENT_TEMPLATE,
  styles: TOOLBAR_BUTTONS_COMPONENT_STYLES,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrossReferenceToolbarButtonsComponent extends AbstractToolbarButtonsComponent<CrossReferenceWithDetailDto, void> implements IczOnChanges {

  private authorizedButtonService = inject(AuthorizedButtonService);
  private crossReferenceModalService = inject(CrossReferenceModalService);
  private documentDetailService = inject(DocumentDetailService, {optional: true});
  private fileDetailService = inject(FileDetailService, {optional: true});

  @Input({required: true})
  esslEntity!: DocumentDto | FileDto;

  override ngOnChanges(changes: IczSimpleChanges<this>): void {
    super.ngOnChanges(changes);

    if (changes.esslEntity) {
      this.generateToolbarContents(this.selectedRows);
    }
  }

  private get entityType() {
    return entityTypeAsDocumentOrFile(this.esslEntity.entityType!)!;
  }

  protected getButtonObs(selection: Array<CrossReferenceWithDetailDto>): Observable<AuthorizedButton[]> {
    return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
      {
        [AuthorizedEntityType.DOCUMENT]: this.entityType === EntityType.DOCUMENT ? this.esslEntity.id : null,
        [AuthorizedEntityType.FILE]: this.entityType === EntityType.FILE ? this.esslEntity.id : null,
      },
      of([
        {
          label: 'Přidat související',
          icon: 'add_new',
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_ADD_RELATED_OBJECT,
            FileAuthorizedOperation.FILE_ADD_RELATED_OBJECT
          ],
          buttonValidators: [
            CommonToolbarButtonValidators.isParentEntityDeactivated([this.esslEntity])
          ],
          isContextFree: true,
          action: () => this.addCrossReferences(),
        },
        {
          label: 'Připojit spis',
          icon: 'add_new',
          show: this.entityType === EntityType.FILE,
          authorizedOperations: [
            FileAuthorizedOperation.FILE_ADD_RELATED_OBJECT
          ],
          buttonValidators: [
            CommonToolbarButtonValidators.isParentEntityDeactivated([this.esslEntity])
          ],
          isContextFree: true,
          action: () => this.addStrongCrossReferences(),
        },
        {
          label: 'Smazat',
          icon: 'delete',
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_REMOVE_RELATED_OBJECT,
            FileAuthorizedOperation.FILE_REMOVE_RELATED_OBJECT,
          ],
          buttonValidators: [
            CommonToolbarButtonValidators.isNoItemSelected(selection),
            CommonToolbarButtonValidators.isParentEntityDeactivated([this.esslEntity])
          ],
          action: () => this.deleteCrossReferences(selection),
        },
      ] as AuthorizedButton[])
    );
  }

  private addCrossReferences() {
    this.crossReferenceModalService.openCrossReferenceCreateDialog({
      entityId: this.esslEntity.id,
      entityType: this.entityType
    }).subscribe(result => {
      if (result) {
        if (this.documentDetailService) {
          this.documentDetailService.reloadObject({
            [DocumentDetailCountType.RELATED]: increment(),
          });
        }
        if (this.fileDetailService) {
          this.fileDetailService.reloadObject({
            [FileDetailCountType.RELATED]: increment(),
          });
        }

        this.operationCompleted.emit();
      }
    });
  }

  private addStrongCrossReferences() {
    if (isFileEntity(this.esslEntity.entityType)) {
      this.crossReferenceModalService.openStrongCrossReferenceCreateDialog(this.esslEntity as FileDto).subscribe(result => {
        if (result) {
          if (this.fileDetailService) {
            this.fileDetailService.reloadObject({
              [FileDetailCountType.RELATED]: increment(),
              [ObjectDetailPart.OBJECT_DATA]: load(),
            });
          }

          this.operationCompleted.emit();
        }
      });
    }
  }

  private deleteCrossReferences(selection: CrossReferenceWithDetailDto[]) {
    this.crossReferenceModalService.openCrossReferenceDeleteDialog({ids: selection.map(r => r.id)}).subscribe(_ => {
      if (this.documentDetailService) {
        this.documentDetailService.reloadObject({
          [DocumentDetailCountType.RELATED]: load(),
        });
      }
      if (this.fileDetailService) {
        this.fileDetailService.reloadObject({
          [FileDetailCountType.RELATED]: load(),
        });
      }

      this.operationCompleted.emit();
    });
  }

}
