<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async" class="h-full">
  <div class="col grow gap-16 h-full">
    <icz-file-selector [form]="form" controlName="fileId" [additionalFilterCriteria]="additionalFilterCriteria" [excludeObjectWithId]="dialogData.id" (objectsSelected)="onFileSelected($event)"></icz-file-selector>
    @if (prepareValidationError) {
      <span class="body-text validation-text">{{ prepareValidationError | translate }}</span>
    } @else {
      @if (isFileSelected && isSCRPrepareLoaded) {
        @if (isEntityClassConflict) {
          <icz-alert severity="warning" heading="Připojením spisů dochází ke konfliktu jejich skartačních režimů. Zkontrolujte a případně upravte skartační režim"></icz-alert>
          <icz-entity-class-selector class="grow" controlName="conflictEntityClassId" label="Věcná skupina, do které budou zatříděny propojené spisy" [form]="form"></icz-entity-class-selector>
          <icz-form-autocomplete
            [clearable]="false"
            [options]="disposalScheduleOptionsFromPrepare"
            class="grow"
            formControlName="conflictDisposalScheduleId"
            label="Skartační režim"
          ></icz-form-autocomplete>
          <div class="gap-24 row grow">
            <div class="col grow">
              <icz-form-autocomplete [options]="disposalOperationCodeOptions" class="grow-2"
                                     formControlName="disposalOperationCode" label="Skartační operace"></icz-form-autocomplete>
              <icz-form-field class="grow-1" formControlName="retentionPeriod" label="Skartační lhůta"></icz-form-field>
              @if (disposalSchedulePrepare) {
                <icz-retention-trigger-type-widget
                  [form]="form"
                  [externalRetentionTriggerIds]="disposalSchedulePrepare.externalRetentionTriggerIds"
                  [disposalScheduleId]="disposalScheduleSourceEntitySelectedId"
                  [retentionTriggerWidgetMode]="retentionTriggerValidationMode"
                  [showExternalRetentionYear]="true"
                ></icz-retention-trigger-type-widget>
              }

            </div>
            <div class="col grow">
              <icz-form-field label="Nejdelší skartační lhůta ve spisu"
                              formControlName="minRetentionPeriod"></icz-form-field>
              <icz-form-field label="Nejpřísnější skartační znak ve spisu"
                              formControlName="minDisposalOperationCode"></icz-form-field>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="filesForStrongCrossReferenceTemplate"></ng-container>
        } @else {
          @if (!sameEntityClass) {
            <icz-alert severity="info" [heading]="noConflictAlertText()"></icz-alert>
          }
          <div class="row">
            <ng-container [ngTemplateOutlet]="filesForStrongCrossReferenceTemplate"></ng-container>
            <div class="grow">
              <icz-entity-class-selector class="grow" controlName="entityClassId" label="Věcná skupina, do které budou zatříděny propojené spisy" [form]="form" [fieldDisabled]="true"></icz-entity-class-selector>
              <icz-form-autocomplete label="Skartační režim" formControlName="disposalScheduleId" [options]="disposalSchedulesOptions$ | async" [fieldDisabled]="true"></icz-form-autocomplete>
            </div>
          </div>
        }
      }
    }
  </div>
</form>

<ng-template #filesForStrongCrossReferenceTemplate>
  <div class="grow">
    <icz-label label="Spisy, které propojujete pevnou vazbou:" class="font-bold"></icz-label>
    @for (file of filesForStrongCrossReference; track file.id) {
      <div class="row mt-8">
        <icz-object-class-icon [objectClass]="file.objectClass" iconSize="default" class="mt-4 block"></icz-object-class-icon>
        <icz-tag [label]="'en.fileState.' + file.fileState" [color]="getObjectTagColor($any(file))"></icz-tag>
        <icz-label [label]="file.refNumber + ' ' + file.subject"></icz-label>
      </div>
    }
  </div>
</ng-template>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Přidat pevnou vazbu na spis"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
