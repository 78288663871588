@if (dataSource && columnsData) {
  <icz-table
    [columnsData]="columnsData"
    [id]="tableId"
    [dataSource]="dataSource"
    (selectedRowsChanged)="selectedRows = $event"
    (contextMenuRequested)="toolbar.openContextMenu($event)"
    (activeRowChanged)="setActiveRow($event)"
    #iczTable
  >

    <ng-content select="[tableTitle]" tableTitle></ng-content>
    <ng-content select="[tableTabs]" tableTabs></ng-content>
    <ng-content select="[noDataLabel]" noDataLabel></ng-content>

    <icz-document-toolbar-buttons
      tools #toolbar
      [selectedRows]="selectedRows"
      [view]="viewType"
      (operationCompleted)="unselectAndReload()"
    ></icz-document-toolbar-buttons>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StorageUnitFillerTableColumn.ID; withEllipsis: true; let column = column">
        <span>
            <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref (click.stop)="openStorageUnitFillerDetailSettingsTab(row)">
              {{ row.id }}
          </a>
        </span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StorageUnitFillerTableColumn.REJECTED_ENTITIES; withEllipsis: true; let column = column">
      @if ($any(row)?.statistics?.entitiesRejectedCount > 0) {
        <span>
            <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref (click.stop)="openStorageUnitFillerDetailErroredEntities(row)">
            {{ $any(row)?.statistics?.entitiesRejectedCount }}
          </a>
        </span>
      } @else {
        {{ $any(row)?.statistics?.entitiesRejectedCount }}
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StorageUnitFillerTableColumn.EXPECTED_ENTITIES; withEllipsis: true; let column = column">
      <span> {{row.statistics?.documentsToInsertCount !== null ? row.statistics?.documentsToInsertCount! + row.statistics?.filesToInsertCount! : ''}}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StorageUnitFillerTableColumn.HUMAN_READABLE_FILTER; withEllipsis: true; let column = column">
      <span class="icz-body-1 mt-4" [innerHTML]="row.humanReadableFilter"></span>
    </ng-container>

  </icz-table>
}
