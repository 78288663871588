import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {
  EntityType,
  RelatedObjectType,
  SubjectObjectRelationDto,
  SubjectRecordClassification,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto,
  SubjectRecordWithRelationsDto
} from '|api/commons';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {
  AbstractObjectDetailService,
  constructSubjectName,
  DocumentDetailService,
  ELASTIC_RELOAD_DELAY,
  esslErrorDtoToToastParameters,
  SubjectOperationResult,
  SubjectOperationType,
  SubjectRecordSource,
  SubjectsService,
  SubjectTableColumnSet,
  SubjectToolbarView,
  UserSettingsService,
  WebSocketNotificationsService
} from '|shared';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  CreateOrUpdateSubjectAction,
  SubjectCreateOrUpdateWithDuplicateResolveService
} from '../../../../../../../shared/src/lib/components/shared-business-components/subjects/subject-create-or-update-with-duplicate-resolve.service';
import {
  SubjectToastService,
  SubjectToastType
} from '../../../../../../../shared/src/lib/core/services/notifications/subject-toast.service';
import {TranslateService} from '@ngx-translate/core';
import {IczInMemoryDatasource} from '@icz/angular-table';
import {delay, take} from 'rxjs/operators';
import {InternalNotificationKey, InternalNotificationMessageCode} from '|api/notification';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  SubjectsToolbarButtonsComponent
} from '../../../../../../../shared/src/lib/components/shared-business-components/subjects/subjects-toolbar-buttons/subjects-toolbar-buttons.component';


@Component({
  selector: 'icz-object-detail-subjects',
  templateUrl: './object-detail-subjects.component.html',
  styleUrls: ['./object-detail-subjects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectDetailSubjectsComponent implements OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected userSettingsService = inject(UserSettingsService);
  protected abstractObjectDetailService = inject(AbstractObjectDetailService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private subjectCreateOrUpdateWithDuplicateResolveService = inject(SubjectCreateOrUpdateWithDuplicateResolveService);
  private subjectsService = inject(SubjectsService);
  private subjectToastService = inject(SubjectToastService);
  private translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);
  private apiSubjectRecordElasticService = inject(ApiSubjectRecordElasticService);

  @ViewChild(SubjectsToolbarButtonsComponent)
  toolbar!: SubjectsToolbarButtonsComponent;

  relatedObjectType = this.abstractObjectDetailService instanceof DocumentDetailService ? RelatedObjectType.DOCUMENT : RelatedObjectType.FILE;
  parentEntityType = this.abstractObjectDetailService instanceof DocumentDetailService ? EntityType.DOCUMENT : EntityType.FILE;

  dataSource = new IczInMemoryDatasource(() => []);
  selectedRows: SubjectRecordDto[] = [];

  // enums for template
  readonly SubjectRecordClassification = SubjectRecordClassification;
  readonly SubjectTableColumnSet = SubjectTableColumnSet;
  readonly SubjectToolbarView = SubjectToolbarView;
  readonly EntityType = EntityType;

  setDataSource() {
    this.abstractObjectDetailService.objectId$.pipe(
      take(1),
    ).subscribe(objectId => {
      this.loadingIndicatorService.doLoading(
      this.apiSubjectRecordElasticService.subjectRecordElasticSearchByRelatedObject({relatedObjectId: objectId, relatedObjectType: this.relatedObjectType!}),
        this)
        .subscribe(res => {
          this.dataSource.setDataFactory(() => res.content!.map(s => (this.subjectsService.getSortableSubject(s, SubjectRecordSource.INTERNAL))));
        });
    });
  }

  onRowClick(rows: SubjectRecordDto[]) {
    this.selectedRows = rows;
  }

  private onUpdateSuccess(subject: SubjectRecordDto | SubjectRecordCreateOrUpdateDto) {
    const name = constructSubjectName(subject);
    this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_UPDATE_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
    this.setDataSource();
  }

  private onUpdateTechnicalError(subject: SubjectRecordCreateOrUpdateDto, error: any) {
    const name = constructSubjectName(subject);

    this.subjectToastService.dispatchSubjectErrorToast(
      SubjectToastType.SUBJECT_UPDATE_ERROR, {
        [InternalNotificationKey.SUBJECT_NAME]: name,
        ...esslErrorDtoToToastParameters(this.translateService, error.error),
      });
  }

  private updateSubject(subjectToUpdate: SubjectRecordCreateOrUpdateDto, relation: SubjectObjectRelationDto) {
    subjectToUpdate.objectRelations = undefined;

    this.loadingIndicatorService.doLoading(
      this.subjectCreateOrUpdateWithDuplicateResolveService.createOrUpdateSubjectWithDuplicateResolve(
        CreateOrUpdateSubjectAction.CREATE_OR_UPDATE_SUBJECT,
        {subject: subjectToUpdate, createRelation: relation},
        this.abstractObjectDetailService.objectId
      ).pipe(delay(ELASTIC_RELOAD_DELAY)),
      this)
      .subscribe(
        {
          next: createAttemptResult => {
            if (!createAttemptResult) {
              return; // no result means closing the duplicate dialog without any action
            }
            else {
              this.onUpdateSuccess(createAttemptResult);
            }
          },
          error: err => {
            this.onUpdateTechnicalError(subjectToUpdate, err);
          }
        }
      );
  }

  toolbarOperationCompleted(result: SubjectOperationResult) {
    if (result.outputSubject && result.operation === SubjectOperationType.IDENTIFY) {
      const relation = (result.inputSubject as SubjectRecordWithRelationsDto).objectRelations!.find(
        or => or.relatedObjectId === this.abstractObjectDetailService.objectId
      )!;
      const subjectToUpdate: SubjectRecordCreateOrUpdateDto = {
        ...this.subjectsService.enrichExistingSubjectWithIdentified(this.selectedRows[0], result.outputSubject).enrichedSubject,
        valueCorrectionMode: result.additionalOperationResult?.valueCorrectionMode};
      this.updateSubject(subjectToUpdate, relation);
    }

    this.setDataSource();
  }

  ngOnInit() {
    this.setDataSource();
    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.SETTLEMENT_DOCUMENT_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.dataSource.reload(true);
      }
    );
  }


}
