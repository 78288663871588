import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {merge, of} from 'rxjs';
import {
  AbstractToolbarButtonsComponent,
  ApplicationRoute,
  CounterTypeGroup,
  createAbsoluteRoute,
  DocumentsRoute,
  ELASTIC_RELOAD_DELAY,
  ExtendedRegistryOfficeTransferActivityDto,
  HistoryService,
  load,
  MainMenuCountsService,
  ObjectDetailPart,
  StorageUnitDetailCountType,
  StorageUnitDetailService,
  StorageUnitDtoWithAuthorization,
  StorageUnitToolbarAction,
  StorageUnitToolbarButtonsService,
  StorageUnitView,
  TOOLBAR_BUTTONS_COMPONENT_STYLES,
  TOOLBAR_BUTTONS_COMPONENT_TEMPLATE,
  TransferableObjectToolbarAction,
  TransferableObjectToolbarButtonsService,
  TransferableObjectView
} from '|shared';
import {delay} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RegistryOfficeTransferDto, StorageUnitDto} from '|api/document';
import {Router} from '@angular/router';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';

@Component({
  selector: 'icz-storage-unit-toolbar-buttons',
  providers: [
    StorageUnitToolbarButtonsService,
    TransferableObjectToolbarButtonsService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: TOOLBAR_BUTTONS_COMPONENT_TEMPLATE,
  styles: TOOLBAR_BUTTONS_COMPONENT_STYLES,
})
export class StorageUnitToolbarButtonsComponent extends AbstractToolbarButtonsComponent<StorageUnitDtoWithAuthorization, StorageUnitView> implements OnInit, IczOnChanges {

  private storageUnitToolbarButtonsService = inject(StorageUnitToolbarButtonsService);
  private storageUnitDetailService = inject(StorageUnitDetailService, {optional: true});
  private historyService = inject(HistoryService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private transferableObjectToolbarButtonsService = inject(TransferableObjectToolbarButtonsService);
  private router = inject(Router);

  @Input()
  isRegistryOfficeTransferActivityView!: boolean;
  @Input()
  selectedStorageUnits: Nullable<StorageUnitDto[]>;

  ngOnInit() {
    merge(
      this.storageUnitToolbarButtonsService.actionCompleted$,
      this.transferableObjectToolbarButtonsService.actionCompleted$,
    ).pipe(
      delay(ELASTIC_RELOAD_DELAY),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(action => {
      this.operationCompleted.emit();

      if (this.storageUnitDetailService) {
        switch (action) {
          case StorageUnitToolbarAction.STORAGE_UNIT_CREATE:
            // reload nothing
            break;
          case StorageUnitToolbarAction.STORAGE_UNIT_ENTITIES_ADD:
            this.storageUnitDetailService.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [StorageUnitDetailCountType.CONTENT]: load(),
            });
            break;
          case StorageUnitToolbarAction.STORAGE_UNIT_BLOCK:
            this.storageUnitDetailService.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
            });
            break;
          case StorageUnitToolbarAction.STORAGE_UNIT_UNBLOCK:
            this.storageUnitDetailService.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
            });
            break;
          case StorageUnitToolbarAction.STORAGE_UNIT_DELETE:
            this.historyService.routeBack(
              createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.SETTLED)
            );
            break;
          case TransferableObjectToolbarAction.TRANSFER_HANDOVER:
            this.storageUnitDetailService.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case TransferableObjectToolbarAction.TRANSFER_TAKEOVER:
          case TransferableObjectToolbarAction.TRANSFER_REJECT:
            if (this.view === StorageUnitView.STORAGE_UNIT_DETAIL) {
              this.storageUnitDetailService.reloadObject({
                [ObjectDetailPart.OBJECT_DATA]: load(),
                [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
              });
              this.router.navigate([this.router.url.split('?')[0]]);
            }
            break;
          case TransferableObjectToolbarAction.TRANSFER_CANCEL:
          case TransferableObjectToolbarAction.TRANSFER_RETRANSFER:
          case TransferableObjectToolbarAction.TRANSFER_RETURN_TO_OFFICE:
            if (this.view === StorageUnitView.STORAGE_UNIT_DETAIL) {
              this.storageUnitDetailService.reloadObject({
                [ObjectDetailPart.OBJECT_DATA]: load(),
              });
              this.mainMenuCountsService.updateMainMenuCounters([
                CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS,
              ]);
            }
            break;
          default:
            this.storageUnitDetailService.reloadObject();
        }
      }

      switch (action) {
        case StorageUnitToolbarAction.STORAGE_UNIT_ENTITIES_ADD:
          this.mainMenuCountsService.updateMainMenuCounters([
            CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS,
          ]);
          break;
      }
    });
  }

  override ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.selectedRows || changes.view || changes.isRegistryOfficeTransferActivityView) {
      this.generateToolbarContents(this.selectedRows ?? []);
    }
  }

  protected getButtonObs(selectedStorageUnits: Nullable<StorageUnitDto[]>) {
      if (this.view === StorageUnitView.STORAGE_UNIT_DETAIL) {
        if (!selectedStorageUnits?.length) return of([]);

        if (this.isRegistryOfficeTransferActivityView) {
          const storageUnit = selectedStorageUnits[0] as StorageUnitDto;

          return this.transferableObjectToolbarButtonsService.getToolbarButtons(
            [this.storageUnitAndTransferToExtendedTransferDto(storageUnit, storageUnit.registryOfficeTransfer!)],
            TransferableObjectView.ENTITY_TRANSFER_IN_PROGRESS
          );
        }
        else {
          return this.toolbarDataService.mergeToolbars(
            this.storageUnitToolbarButtonsService.getToolbarButtons(selectedStorageUnits, this.view),
            this.transferableObjectToolbarButtonsService.getToolbarButtons(selectedStorageUnits, TransferableObjectView.STORAGE_UNIT_DETAIL),
          );
        }
      }
      else if (this.view === StorageUnitView.STORAGE_UNITS_TABLE) {
        return this.toolbarDataService.mergeToolbars(
          this.storageUnitToolbarButtonsService.getToolbarButtons(selectedStorageUnits!, this.view),
          this.transferableObjectToolbarButtonsService.getToolbarButtons(selectedStorageUnits, TransferableObjectView.STORAGE_UNITS_TABLE),
        );
      }
      else {
        return of([]);
      }
  }

  private storageUnitAndTransferToExtendedTransferDto(storageUnit: StorageUnitDtoWithAuthorization, transfer: RegistryOfficeTransferDto) {
    return {
      ...storageUnit,
      ...transfer
    } as unknown as ExtendedRegistryOfficeTransferActivityDto;
  }

}
