import {
  AbstractFileSettleFormComponent
} from '../file-settle-dialog/abstract-file-settle-form.component';
import {Directive, Input} from '@angular/core';
import {EntityClassDto} from '|api/codebook';
import {FilePrepareCompletionFileTypeDto} from '|api/document';
import {IczOption} from '@icz/angular-form-elements';
import {IczInMemoryDatasource} from '@icz/angular-table';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {namedDtoToOption} from '|shared';

@Directive()
export class AbstractFileCloseForm extends AbstractFileSettleFormComponent implements IczOnChanges {
  @Input()
  availableEntityClasses: Nullable<EntityClassDto[]>;
  @Input()
  availableFileTypes?: Nullable<FilePrepareCompletionFileTypeDto[]>;

  entityClassOptions: IczOption[] = [];
  fileTypeOptions: IczOption[] = [];
  validEntityClassesDataSource = new IczInMemoryDatasource(() => []);

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.availableEntityClasses?.currentValue) {
      this.entityClassOptions = this.availableEntityClasses!.map(ec => namedDtoToOption()(ec));
      this.validEntityClassesDataSource = new IczInMemoryDatasource(() => this.availableEntityClasses!);
    }
    if (changes?.availableFileTypes?.currentValue) {
      this.fileTypeOptions = this.availableFileTypes!.map(ft => {return {value: ft.id!, label: ft.label!};});
    }

    if (changes.disposalSchedulePrepare && changes.disposalSchedulePrepare.currentValue) {
      this.fillDisposalScheduleSectionWithPrepare(changes.disposalSchedulePrepare.currentValue);
    }
  }
}
