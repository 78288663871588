import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  DisposalTipDetailLevel,
  DocumentSearchService,
  DocumentView,
  getObjectIcon,
  getSuperclassEntityType,
  isDocumentObject,
  isFileObject,
  StorageUnitSearchService
} from '|shared';
import {ApiStorageUnitService, DocumentDto, FileDto, StorageUnitDto,} from '|api/document';
import {
  ObjectsForStorageUnitInsertDatasource
} from './objects-for-storage-unit-insert.datasource';
import {ObjectClass, OperationEntityDto} from '|api/commons';
import {
  StorageUnitSelectorDialogComponent,
  StorageUnitSelectorDialogData
} from '../storage-unit-selector-dialog/storage-unit-selector-dialog.component';
import {Observable} from 'rxjs';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {IczModalService, IczModalSizeClass, injectModalData, injectModalRef} from '@icz/angular-modal';

export type StorageUnitInsertableObject = DocumentDto | FileDto;

export interface StorageUnitInsertDialogData {
  isMove: boolean;
  storageUnitId: Nullable<number>;
  selectedObjects?: (FileDto| DocumentDto)[];
}

@Component({
  selector: 'icz-storage-unit-insert-dialog',
  templateUrl: './storage-unit-insert-dialog.component.html',
  styleUrls: ['./storage-unit-insert-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageUnitInsertDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private documentSearchService = inject(DocumentSearchService);
  private storageUnitSearchService = inject(StorageUnitSearchService);
  private storageUnitService = inject(ApiStorageUnitService);
  private modalService = inject(IczModalService);
  protected modalData = injectModalData<StorageUnitInsertDialogData>();

  form = new IczFormGroup({
    selectedStorageUnit: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  dataSource = new ObjectsForStorageUnitInsertDatasource(this.documentSearchService, this.modalData.storageUnitId);
  readonly DocumentView = DocumentView;
  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;
  selectedObjects: StorageUnitInsertableObject[] = [];
  selectedStorageUnit: Nullable<StorageUnitDto>;
  showDocumentSelection = false;

  ngOnInit() {
    this.showDocumentSelection = !isNil(this.modalData.storageUnitId) && !this.modalData.isMove;
  }

  selectionChanged(selection: StorageUnitInsertableObject[]) {
    this.selectedObjects = selection;
  }

  isSubmitDisabled() {
    if (this.showDocumentSelection) {
      return this.selectedObjects.length === 0;
    } else {
      return isNil(this.selectedStorageUnit);
    }
  }

  submit() {
    let request$!: Observable<void>;

    if (this.modalData.isMove) {
      request$ = this.storageUnitService.storageUnitBulkMoveEntities({
        id: this.modalData.storageUnitId!,
        body: {
          storageUnitId: this.selectedStorageUnit!.id,
          entitiesToMove: this.modalData.selectedObjects!.map(obj => ({
            id: obj.id!,
            entityType: getSuperclassEntityType(obj.entityType!),
          })),
        },
      });
    }
    else {
      let requestDto: OperationEntityDto[];
      let requestStorageUnitId: number;

      if (this.showDocumentSelection) {
        requestStorageUnitId = this.modalData.storageUnitId!;
        requestDto = this.selectedObjects.map(obj => ({
          id: obj.id!,
          entityType: getSuperclassEntityType(obj.entityType!),
        }));
      }
      else {
        requestStorageUnitId = this.selectedStorageUnit!.id;
        requestDto = this.modalData.selectedObjects!.map(obj => ({
          id: obj.id!,
          entityType: getSuperclassEntityType(obj.entityType!),
        }));
      }

      request$ = this.storageUnitService.storageUnitBulkInsertEntities({
        id: requestStorageUnitId!,
        body: requestDto,
      });
    }

    this.loadingService.doLoading(
      request$,
      this,
    ).subscribe(_ => {
      this.modalRef.close(true);
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

  getObjectIcon(): string {
    if (this.selectedStorageUnit) {
      return getObjectIcon(this.selectedStorageUnit.objectClass as unknown as ObjectClass)!;
    } else {
      return '';
    }
  }

  openStorageUnitSelection() {
    this.modalService.openComponentInModal<StorageUnitDto, StorageUnitSelectorDialogData>({
      component: StorageUnitSelectorDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_RESPONSIVE,
        titleTemplate: 'Vyhledejte ukládací jednotku',
        disableAutoMargin: true
      },
      data: {
        objectsToInsert: this.modalData.selectedObjects ?? [],
        isMove: this.modalData.isMove,
      }
    }).subscribe(result => {
      if (result) {
        this.form.get('selectedStorageUnit')!.setValue(result.name);
        this.selectedStorageUnit = result;
      }
    });
  }

  isStorageUnitSelected() {
    return Boolean(this.form.get('selectedStorageUnit')!.value);
  }

  clearClicked($event: Event) {
    $event.stopPropagation();
    this.form.get('selectedStorageUnit')!.setValue(null);
  }

  getDocumentsCount() {
    return this.modalData.selectedObjects?.filter(o => isDocumentObject(o)).length;
  }

  getFilesCount() {
    return this.modalData.selectedObjects?.filter(o => isFileObject(o)).length;
  }

  get placeholder() {
    return this.isStorageUnitSelected() ? null : 'hledejte v čísle jednotky, UID, názvu nebo popisu jednotky';
  }

  get viewValue() {
    return this.form.get('selectedStorageUnit')!.value;
  }

}
