<form [formGroup]="formGroup" [waiting]="loadingService.isLoading$(this) | async">
  <div class="col">
    <icz-form-field
      formControlName="reason"
      [label]="data.isDocument ? 'Důvod zničení dokumentu' : 'Důvod zničení spisu'"
    ></icz-form-field>
  </div>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary [label]="data.isDocument ? 'Zničit dokument' : 'Zničit spis'"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
