<div class="col grow h-full w-full">
  <ng-content select="[toolbar]"></ng-content>
  @if (abstractObjectDetailService.lockedState$ | async) {
    <div class="toolbar-divider">
      <icz-alert alertMode="compact" severity="info" [label]="getLockedStateText()"></icz-alert>
    </div>
  }
  <div class="col grow overflow-hidden">
    <icz-tabs [baseUrl]="baseUrl$ | async" [queryParams]="queryParams$ | async" [tabs]="tabs" class="tabs"></icz-tabs>
    <ng-content></ng-content>
  </div>
  @if (showSubmitBar) {
    <icz-submit-bar
      [savingProgress]="loadingService.isLoading(this)"
      [submitDisabled]="isSubmitDisabled"
      (submit)="abstractObjectDetailService.submit$.next(ObjectDetailSubmitActionType.SAVE)"
      (cancel)="abstractObjectDetailService.cancel$.next()"
      [isNewOrLabel]="saveButtonLabel"
      >
      <icz-button
        iczFormSubmit
        [disabled]="isSubmitDisabled || loadingService.isLoading(this)"
        [label]="saveAndCloseButtonLabel"
        (onAction)="abstractObjectDetailService.submit$.next(ObjectDetailSubmitActionType.SAVE_AND_CLOSE)"
      ></icz-button>
    </icz-submit-bar>
  }
</div>
