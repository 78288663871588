import {IczTableDataSource} from '@icz/angular-table';
import {EntityType} from '|api/commons';
import {CrossReferenceWithDetailDto} from '|api/document';
import {CrossReferenceSearchService} from '|shared';

export class CrossReferenceObjectsDatasource extends IczTableDataSource<CrossReferenceWithDetailDto> {
  constructor(
    crossReferenceSearchService: CrossReferenceSearchService,
    entityType: EntityType,
    entityId: number
  ) {
    super(searchParams => crossReferenceSearchService.findCrossReferenceEntitiesForRelatedView(entityId, entityType, searchParams));
  }
}
