import {DestroyRef, Directive, EventEmitter, inject, Input, OnInit, Output, signal} from '@angular/core';
import {
  CodebookService,
  enumToOptions,
  formatDisposalSchedule,
  getEntityClassSelectorSchema,
  processRetentionTriggerCheckYearChange,
  processRetentionTriggerTypeChange,
  processRetentionTriggerYearChange
} from '|shared';
import {ClassificationSchemeDto, DisposalScheduleDto, EntityClassDto} from '|api/codebook';
import {DisposalOperationCode, RetentionTriggerTypeCode} from '|api/commons';

import {combineLatest} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  DisposalScheduleComputationDto,
  SimplifiedDisposalScheduleDto
} from '|api/document';
import {IczFormControl, IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {TableColumnsData} from '@icz/angular-table';
import {
  FileSettleStateService
} from './file-settle-state.service';

@Directive()
export class AbstractFileSettleFormComponent implements OnInit {
  private codebookService = inject(CodebookService);
  protected destroyRef = inject(DestroyRef);
  protected fileSettleStateService = inject(FileSettleStateService);

  @Input({required: true}) form!: IczFormGroup;
  @Output() disposalScheduleSourceChange = new EventEmitter<void>();
  @Input() disposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>;
  isGeneralFileSettleForm = false;

  disposalSchedules: DisposalScheduleDto[] = [];
  retentionTriggerTypesOptions: IczOption[] = enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode);
  disposalOperationCodeOptions = enumToOptions('disposalOperationCode', DisposalOperationCode);
  classificationSchemes: ClassificationSchemeDto[] = [];

  isRetentionTriggerTypeWithPeriod = signal(false);
  isExternalRetentionTriggerTypeWithPeriod = signal(false);
  allExternalRetentionTriggers: IczOption[] = [];
  availableDisposalSchedules:  SimplifiedDisposalScheduleDto[] = [];
  disposalScheduleOptions: IczOption[] = [];
  entityClassSelectorSchema: TableColumnsData<keyof EntityClassDto> = new TableColumnsData<never>([]);

  get entityClassId() {
    return this.form ? this.form.get('entityClassId')!.value : null;
  }

  get documentTypeId() {
    return this.form ? this.form.get('documentTypeId')!.value : null;
  }

  get disposalScheduleIdCtrl() {
    return this.form.get('disposalScheduleId')!;
  }

  get retentionTriggerTypeCodeCtrl() {
    return this.form.get('retentionTriggerTypeCode');
  }

  get retentionTriggerYearCtrl() {
    return this.form.get('yearOfRetentionPeriodStart') as IczFormControl;
  }

  get retentionTriggerCheckYearCtrl() {
    return this.form.get('triggerEventCheckYear') as IczFormControl;
  }

  codebooksLoaded() {}

  ngOnInit () {
    combineLatest([
      this.codebookService.disposalSchedules(),
      this.codebookService.externalRetentionTriggers(),
      this.codebookService.classificationSchemes(),
      this.codebookService.securityCategories(),
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([disposalSchedules, externalRetentionTriggers, classificationSchemes, securityCategories]) => {
      this.disposalSchedules = disposalSchedules;
      this.classificationSchemes = classificationSchemes;
      this.allExternalRetentionTriggers = externalRetentionTriggers.map(rt => ({
        value: rt.id,
        label: `${rt.code} ${rt.description}`,
      }));
      this.entityClassSelectorSchema = getEntityClassSelectorSchema(disposalSchedules, securityCategories);
      this.codebooksLoaded();
    });

    this.retentionTriggerTypeCodeCtrl!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selectedRTT: Nullable<RetentionTriggerTypeCode>) => {
      processRetentionTriggerTypeChange(selectedRTT, this.isRetentionTriggerTypeWithPeriod, this.isExternalRetentionTriggerTypeWithPeriod, this.retentionTriggerYearCtrl, this.retentionTriggerCheckYearCtrl);
    });

    this.retentionTriggerYearCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      processRetentionTriggerYearChange(value, this.retentionTriggerCheckYearCtrl);
    });

    this.retentionTriggerCheckYearCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      processRetentionTriggerCheckYearChange(value, this.retentionTriggerYearCtrl);
    });

    this.disposalScheduleIdCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      const selectedDs = this.availableDisposalSchedules.find(ds => ds.disposalScheduleId === value);
      this.fillRetentionSection(selectedDs);
    });

    if (this.disposalScheduleIdCtrl.value && !this.isGeneralFileSettleForm) {
      const selectedDs = this.availableDisposalSchedules.find(ds => ds.disposalScheduleId === this.disposalScheduleIdCtrl.value);
      this.fillRetentionSection(selectedDs);
    }
  }

  fillRetentionSection(selectedDs: Nullable<SimplifiedDisposalScheduleDto>) {
    if (selectedDs) {
      this.form.get('retentionTriggerTypeCode')!.setValue(selectedDs?.retentionTriggerTypeCode);
      this.form.get('disposalOperationCode')!.setValue(selectedDs?.disposalOperationCode);
      this.form.get('retentionPeriod')!.setValue(selectedDs.retentionPeriod);
      this.form.get('triggerEventCheckYear')!.setValue((new Date()).getFullYear() + (selectedDs.defaultTriggerEventCheckYear ?? 0));
    }
  }

  fillDisposalScheduleSectionWithPrepare(disposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>, fillFormValues = true) {
    if (disposalSchedulePrepare) {
      if (disposalSchedulePrepare.availableDisposalSchedules) {
        this.availableDisposalSchedules = disposalSchedulePrepare.availableDisposalSchedules;
        this.disposalScheduleOptions = disposalSchedulePrepare.availableDisposalSchedules.map(ds => ({value: ds.disposalScheduleId, label: formatDisposalSchedule(ds)}));
        if (fillFormValues) {
          const recommendedDS = disposalSchedulePrepare.availableDisposalSchedules.find(ds => ds.disposalScheduleId === disposalSchedulePrepare.recommendedDisposalScheduleId)!;
          if (disposalSchedulePrepare.recommendedDisposalScheduleId && isNil(this.form.get('disposalScheduleId')!.value)) {
            this.form.get('disposalScheduleId')!.setValue(disposalSchedulePrepare.recommendedDisposalScheduleId);
          }
          if (disposalSchedulePrepare.defaultYearOfRetentionPeriodStart) {
            this.form.get('yearOfRetentionPeriodStart')!.setValue(disposalSchedulePrepare.defaultYearOfRetentionPeriodStart);
          }
          this.form.get('externalRetentionTriggerIds')!.setValue(disposalSchedulePrepare.externalRetentionTriggerIds);
          if (recommendedDS) {
            this.form.get('minDisposalOperationCode')!.setValue(recommendedDS.disposalOperationCode);
            this.form.get('minRetentionPeriod')!.setValue(recommendedDS.retentionPeriod);
          }
        }
      }
    }
  }
}
