<icz-card class="icz-body-1">
  <div class="row-group items-center">
    <div class="object-icon">
      <icz-object-class-icon [objectClass]="associatedObjectClass" class="mr-8"></icz-object-class-icon>
    </div>
    <div>
      <span class="mr-4 ml-16">{{heading | translate}}</span>
      <ng-content></ng-content>
    </div>
  </div>
</icz-card>
