@if (dataSource) {
  <icz-documents-table tableId="file-detail-content" class="grow"
                       [viewType]="DocumentView.FILE_CONTENTS"
                       [hideTableToolbarButtons]="true"
                       (selectedRowsChanged)="selectedRows = $any($event)"
                       [dataSource]="$any(dataSource)">
    <icz-label tableTitle label="Nevyřízené dokumenty"></icz-label>
    <div beforeToolbar class="mt-4 mr-16">
      <icz-button label="Vybrat komponenty" [disabled]="selectedRows.length !== 1" svgIcon="edit"
                  (onAction)="openComponentSelectionPerDocument(selectedRows)">
      </icz-button>
    </div>
  </icz-documents-table>
}
