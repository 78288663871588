import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ObjectClass, StorageUnitAllowableObjectClass} from '|api/commons';
import {CaseFileAllowableObjectClass, FileAllowableObjectClass} from '|api/document';
import {DocumentAllowableObjectClass} from '|api/sad';


@Component({
  selector: 'icz-object-association-alert',
  templateUrl: './object-association-alert.component.html',
  styleUrls: ['./object-association-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectAssociationAlertComponent {

  @Input({required: true})
  heading!: string;
  @Input({required: true})
  associatedObjectClass!: ObjectClass | DocumentAllowableObjectClass | FileAllowableObjectClass | StorageUnitAllowableObjectClass | CaseFileAllowableObjectClass;

}
