<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">


  <div class="row">
    <div class="col grow">
      <div class="row">
        <icz-form-autocomplete  class="grow" label="Křížový odkaz" formControlName="name" [options]="templateOptions"></icz-form-autocomplete>
        <div class="grow"></div>
      </div>
      <div class="row">
        <icz-form-autocomplete
          class="grow-2"
          #entitySelector
          [noResultsTemplate]="noResultsTemplate"
          [isMultiselect]="true"
          formControlName="entitiesReferringTo"
          [options]="recentlyVisitedEntitiesOptions"
          [optionsDefinitionFactory]="makeEntitiesSearchOptionsDefinition"
          label="Vyhledat objekt"
          placeholder="Hledejte v názvu objektu"
          [minSearchTermLength]="minSearchTermLength"
        ></icz-form-autocomplete>
        <icz-form-autocomplete label="Prohledávat pouze některé objekty" formControlName="entityType" [options]="entityTypeOptions" class="grow"></icz-form-autocomplete>
      </div>
    </div>
  </div>
</form>

<ng-template #noResultsTemplate let-context>
  <div class="no-options-found">
    {{ ((context.searchTerm?.length ?? 0) >= minSearchTermLength ? 'Žádné spisy nenalezeny' : 'Pro vyhledání zadejte minimálně tři znaky') | translate }}
  </div>
  <!-- Hack to show the recently visited list when searchTermLength is < 3. -->
  <icz-options-list
    [options]="recentlyVisitedEntitiesOptions"
    (selectionChanged)="recentlyVisitedEntitySelected($event)"
  ></icz-options-list>
</ng-template>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Přidat odkaz na objekt"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
