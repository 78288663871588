<form [formGroup]="form">
  @if (boxed) {
    <icz-section boxed label="Rozsah dokumentu">
      <ng-container *ngTemplateOutlet="content">
      </ng-container>
    </icz-section>
  }
  @else {
    @switch (form.get('documentForm')?.value) {
      @case (DocumentForm.ANALOG) {
        <div class="row">
          <icz-form-field label="Počet listinných komponent" class="grow" [value]="complexPaperComponentsCounts" [fieldDisabled]="true"></icz-form-field>
          <icz-form-field label="Počet nelistinných komponent" class="grow" [value]="nonPaperAnalogComponentsSum" [fieldDisabled]="true"></icz-form-field>
        </div>
      }
      @case (DocumentForm.DIGITAL) {
        <div>
          <icz-form-field label="Počet digitálních komponent" formControlName="digitalComponentCount" class="grow"></icz-form-field>
        </div>
      }
      @case (DocumentForm.HYBRID) {
        <div>
          <icz-form-field label="Počet listinných komponent" [value]="complexPaperComponentsCounts" [fieldDisabled]="true"></icz-form-field>
          <div class="row">
            <icz-form-field label="Počet digitálních komponent" formControlName="digitalComponentCount" class="grow"></icz-form-field>
            <icz-form-field label="Počet nelistinných komponent" class="grow" [value]="nonPaperAnalogComponentsSum" [fieldDisabled]="true"></icz-form-field>
          </div>
        </div>
      }
    }
  }

  <ng-template #content>
    @switch (form.get('documentForm')?.value) {
      @case (DocumentForm.ANALOG) {
        <div class="row">
          <icz-form-field label="Počet listinných komponent" class="grow" [value]="complexPaperComponentsCounts" [fieldDisabled]="true"></icz-form-field>
          <icz-form-field label="Počet nelistinných komponent" class="grow" [value]="nonPaperAnalogComponentsSum" [fieldDisabled]="true"></icz-form-field>
        </div>
      }
      @case (DocumentForm.DIGITAL) {
        <div>
          <icz-form-field label="Počet digitálních komponent" formControlName="digitalComponentCount" class="grow"></icz-form-field>
        </div>
      }
      @case (DocumentForm.HYBRID) {
        <div>
          <icz-form-field label="Počet listinných komponent" [value]="complexPaperComponentsCounts" [fieldDisabled]="true"></icz-form-field>
          <div class="row">
            <icz-form-field label="Počet digitálních komponent" formControlName="digitalComponentCount" class="grow"></icz-form-field>
            <icz-form-field label="Počet nelistinných komponent" class="grow" [value]="nonPaperAnalogComponentsSum" [fieldDisabled]="true"></icz-form-field>
          </div>
        </div>
      }
    }
  </ng-template>
</form>
