import {Component, inject, OnInit} from '@angular/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {TranslateService} from '@ngx-translate/core';
import {ApiDocumentService, ApiFileService, DisposalDto, DisposedEntityDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {
  CheckUnsavedFormDialogService,
  DocumentToastService,
  DocumentToastType,
  esslErrorDtoToToastParameters,
  FileToastService,
  FileToastType,
  IFormGroupCheckable,
} from '|shared';

export interface DocumentFileDisposeDialogData {
  entityId: number;
  subject: string;
  isDocument: boolean;
}

@Component({
  selector: 'icz-document-file-dispose-dialog',
  templateUrl: './document-file-dispose-dialog.component.html',
  styleUrls: ['./document-file-dispose-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class DocumentFileDisposeDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<DisposedEntityDto>>();
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private translateService = inject(TranslateService);
  private apiDocumentService = inject(ApiDocumentService);
  private apiFileService = inject(ApiFileService);
  private documentToastService = inject(DocumentToastService);
  private fileToastService = inject(FileToastService);
  protected data = injectModalData<DocumentFileDisposeDialogData>();

  formGroup = new IczFormGroup({
    reason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  headingContext!: {entityTypeToHeading_1: string, entityTypeToHeading_2: string};

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['formGroup']);

    if (this.data.isDocument) {
      this.headingContext = {
        entityTypeToHeading_1: this.translateService.instant('dokumentu'),
        entityTypeToHeading_2: this.translateService.instant('dokument'),
      };
    } else {
      this.headingContext = {
        entityTypeToHeading_1: this.translateService.instant('spisu'),
        entityTypeToHeading_2: this.translateService.instant('spis'),
      };
    }
  }

  submit() {
    const requestDto: DisposalDto = {
      reason: this.formGroup.get('reason')!.value,
    };

    const deactivationReq$ = this.data.isDocument ?
      this.apiDocumentService.documentDisposeOfDocument({documentId: this.data.entityId, body: requestDto}) :
      this.apiFileService.fileDisposeOfFile({fileId: this.data.entityId, body: requestDto});

    this.loadingService.doLoading(
      deactivationReq$,
      this,
    ).subscribe({
      next: disposedEntity => {
        if (this.data.isDocument) {
          this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.DOCUMENT_DISPOSED, {
            [InternalNotificationKey.DOCUMENT_ID]: this.data.entityId,
            [InternalNotificationKey.DOCUMENT_SUBJECT]: this.data.subject,
          });
        } else {
          this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_DISPOSED, {
            [InternalNotificationKey.FILE_ID]: this.data.entityId,
            [InternalNotificationKey.FILE_SUBJECT]: this.data.subject,
          });
        }
        this.modalRef.close(disposedEntity);
      },
      error: error => {
        if (this.data.isDocument) {
          this.documentToastService.dispatchDocumentErrorToast(
            DocumentToastType.DOCUMENT_DISPOSE_ERROR,
            esslErrorDtoToToastParameters(this.translateService, error.error),
          );
        } else {
          this.fileToastService.dispatchFileErrorToast(
            FileToastType.FILE_DISPOSE_ERROR,
            esslErrorDtoToToastParameters(this.translateService, error.error),
          );
        }
      }
    });
  }

  cancel() {
    this.modalRef.close(null);
  }
}
