@if (dataSource && columnsData) {
  <icz-table
    [columnsData]="columnsData"
    [id]="tableId"
    [dataSource]="dataSource"
    (selectedRowsChanged)="selectedRows = $event"
    (contextMenuRequested)="toolbar.openContextMenu($event)"
    (activeRowChanged)="setActiveRow($event)"
    #iczTable
  >

    <ng-content select="[tableTitle]" tableTitle></ng-content>
    <ng-content select="[tableTabs]" tableTabs></ng-content>
    <ng-content select="[noDataLabel]" noDataLabel></ng-content>

    <icz-storage-unit-toolbar-buttons
      tools #toolbar
      [selectedRows]="selectedRows"
      [view]="viewType"
      (operationCompleted)="unselectAndReload()"
    ></icz-storage-unit-toolbar-buttons>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StorageUnitTableColumn.CONTENT_BLOCKED; withEllipsis: true; let column = column">
      <icz-icon [svgIcon]="getStorageUnitBlockedIcon(row)" size="small"></icz-icon>
    </ng-container>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.OBJECT_CLASS">
      <icz-table-entity-icon
        [documentsTableEntity]="row"
        [view]="viewType"
      ></icz-table-entity-icon>
    </ng-container>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StorageUnitTableColumn.NUMBER; withEllipsis: true; let column = column">
        <span class="entity-link-progress-indicator-wrapper" [class.is-locked]="row.lockedState">
          <icz-static-progress-indicator class="entity-link-progress-indicator"></icz-static-progress-indicator>
          @if (viewType !== StorageUnitView.STORAGE_UNIT_SELECT) {
            <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref (click.stop)="openStorageUnitDetail(row)">
              {{ row.storageUnitNumber }}
            </a>
          } @else {
            {{ row.storageUnitNumber }}
          }
        </span>
    </ng-container>

  </icz-table>
}
