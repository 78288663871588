import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  AbstractFileSettleFormComponent
} from '../abstract-file-settle-form.component';
import {
  enumToOptions,
  VisibleClassifiersMode
} from '|shared';
import {SettlementType} from '|api/commons';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczOption} from '@icz/angular-form-elements';

@Component({
  selector: 'icz-general-file-settle-form',
  templateUrl: './general-file-settle-form.component.html',
  styleUrls: ['./general-file-settle-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralFileSettleFormComponent extends AbstractFileSettleFormComponent implements OnInit, IczOnChanges {
  @Input()
  minSettlementDate!: Date;
  @Input()
  bulkSettlementDate!: Date;


  protected readonly VisibleClassifiersMode = VisibleClassifiersMode;
  generalTabFileSettlementTypeOptions: IczOption[] = enumToOptions('settlementType', SettlementType);
  isBulkValidSettlementDate = {
    validationFn: (date: Date) => this.isDateValid(date),
    invalidDateMessage : { errorMessageTemplate: 'Datum nesmí být starší než datum založení spisu'}
  };

  override ngOnInit() {
    this.isGeneralFileSettleForm = true;
    super.ngOnInit();
  }

  maxSettlementDate = new Date();

  private isDateValid(date: Date) {
    if (!(date && this.minSettlementDate)) return false;
    return !(date < this.minSettlementDate || date > this.maxSettlementDate);
  }

  get isBulkSettlementByRecord() {
    return this.form.get('fileSettlementType')!.value === SettlementType.BY_RECORD;
  }

  get isBulkSettlementByDocument() {
    return this.form.get('fileSettlementType')!.value === SettlementType.BY_DOCUMENT;
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.disposalSchedulePrepare && changes.disposalSchedulePrepare.currentValue) {
      this.fillDisposalScheduleSectionWithPrepare(changes.disposalSchedulePrepare.currentValue, !this.fileSettleStateService.generalTabInitialized);
      this.fileSettleStateService.generalTabInitialized = true;
    }
  }
}
