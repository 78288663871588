import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {FilterType, RowDisableFn, TableColumnsData, TableReservedColumns} from '@icz/angular-table';
import {CrossReferenceType} from '|api/commons';
import {CrossReferenceWithDetailDto, DocumentDto, FileDto} from '|api/document';
import {
  CrossReferenceObjectsDatasource
} from './cross-reference-objects.datasource';
import {CrossReferenceSearchService, entityTypeAsDocumentOrFile, EntityWithRefNumber, enumToOptions,} from '|shared';


@Component({
  selector: 'icz-cross-reference-table',
  templateUrl: './cross-reference-table.component.html',
  styleUrls: ['./cross-reference-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrossReferenceTableComponent implements IczOnChanges {

  private crossReferenceSearchService = inject(CrossReferenceSearchService);

  @Input({required: true})
  esslEntity!: DocumentDto | FileDto;

  @Output()
  crossReferenceCountChanged = new EventEmitter<void>();

  selectedRows: CrossReferenceWithDetailDto[] = [];

  dataSource!: CrossReferenceObjectsDatasource;

  rowSelectionDisabler: RowDisableFn = row => {
    return !(row.valid && (row.type === CrossReferenceType.WEAK_BY_USER || row.type === CrossReferenceType.STRONG));
  };

  columnsData = new TableColumnsData<keyof CrossReferenceWithDetailDto|'role'>([
    {id: TableReservedColumns.SELECTION, label: 'Výběr', filterType: FilterType.NONE},
    {id: 'objectClass', label: 'Druh', filterType: FilterType.NONE, fixedWidth: 40,
      disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
    },
    {id: 'refNumber', label: 'Číslo jednací/spisová značka', filterType: FilterType.TEXT,
      disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
    },
    {id: 'subject', label: 'Věc', filterType: FilterType.TEXT,
      disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
    },
    {id: 'name', label: 'Odkaz', filterType: FilterType.TEXT},
    {id: 'type', label: 'Typ', filterType: FilterType.ENUM, list: enumToOptions('crossReferenceType', CrossReferenceType), displayed: false, allowTranslation: true},
    {id: 'role', label: 'Role', filterType: FilterType.NONE, disableSort: true},
    {id: 'ownerFunctionalPositionId', label: 'Zpracovatel', filterType: FilterType.NONE, disableSort: true},
    {id: 'createdAt', label: 'Založen', filterType: FilterType.DATE, displayed: false},
  ]);

  private get entityType() {
    return entityTypeAsDocumentOrFile(this.esslEntity.entityType!)!;
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.esslEntity && this.esslEntity) {
      this.setDataSource();
    }
  }

  thisEntityRoleInReference(row: CrossReferenceWithDetailDto): string {
    const unknownRoleLabel = 'Nepodařilo se určit roli';
    if (row.entityReferringFromId === this.esslEntity.id && row.entityReferringFromType === entityTypeAsDocumentOrFile(this.esslEntity.entityType!)) {
      return row.roleReferringTo;
    }
    else if (row.entityReferringToId === this.esslEntity.id && row.entityReferringToType === entityTypeAsDocumentOrFile(this.esslEntity.entityType!)) {
      return row.roleReferringFrom;
    }
    else {
      return unknownRoleLabel;
    }
  }

  setDataSource() {
    if (this.esslEntity && isNil(this.dataSource)) {
      this.dataSource = new CrossReferenceObjectsDatasource(this.crossReferenceSearchService, this.entityType, this.esslEntity.id);
    }
  }

  isCrossDirection(entity: CrossReferenceWithDetailDto) {
    return entity.entityReferringFromId === this.esslEntity.id && entity.entityReferringFromType === this.entityType;
  }

  getEntityLink(entity: CrossReferenceWithDetailDto): EntityWithRefNumber {
    const entityId = this.isCrossDirection(entity) ? entity.entityReferringToId : entity.entityReferringFromId;
    return {
      refNumber: entity.refNumber,
      entityType: entity.entityReferringToType,
      objectClass: entity.objectClass,
      id: entityId
    } as unknown as EntityWithRefNumber;
  }

  onOperationCompleted() {
    this.dataSource.reload(true);
    this.crossReferenceCountChanged.emit();
  }

}
