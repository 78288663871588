import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal} from '@angular/core';
import {
  CheckUnsavedFormDialogService,
  CodebookService,
  ConfigPropValueService,
  enumToOptions,
  FileConfigKeys,
  FileToastService,
  FileToastType,
  formatDisposalSchedule,
  getObjectTagColor,
  IFormGroupCheckable,
  interpolateBackendErrorMessage,
  namedDtosToOptionsWithCode,
  RetentionTriggerWidgetMode, SKIP_ERROR_DIALOG,
  StrongCrossReferenceType
} from '|shared';
import {getTodayMidnight, IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {
  ApiCrossReferenceService,
  ApiFileService,
  DisposalScheduleComputationDto,
  DisposalScheduleComputationInnerRequestDto,
  DisposalScheduleComputationRequestDto,
  FileDto,
  SimplifiedDisposalScheduleDto
} from '|api/document';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {BehaviorSubject} from 'rxjs';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {DisposalOperationCode, FileState, RetentionTriggerTypeCode} from '|api/commons';
import {InternalNotificationKey} from '|api/notification';
import {FilterOperator, FilterParam} from '@icz/angular-table';

@Component({
  selector: 'icz-strong-cross-reference-create-dialog',
  templateUrl: './strong-cross-reference-create-dialog.component.html',
  styleUrls: ['./strong-cross-reference-create-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrongCrossReferenceCreateDialogComponent implements OnInit, IFormGroupCheckable {

  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected dialogData = injectModalData<FileDto>();
  private destroyRef = inject(DestroyRef);
  private codebookService = inject(CodebookService);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private configService = inject(ConfigPropValueService);
  protected apiFileService = inject(ApiFileService);
  private apiCrossReferenceService = inject(ApiCrossReferenceService);
  private fileToastService = inject(FileToastService);

  protected readonly getObjectTagColor = getObjectTagColor;

  formGroupsToCheck!: string[];
  filesForStrongCrossReference: FileDto[] = [];
  allFilesForStrongCrossReference: FileDto[] = [];
  closedFilesForStrongCrossReference: FileDto[] = [];
  disposalSchedulesOptions$ = this.codebookService.disposalSchedules().pipe(namedDtosToOptionsWithCode);
  fileSCRLeadingFile: Nullable<StrongCrossReferenceType>;
  isEntityClassConflict = false;
  disposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>;
  disposalScheduleOptionsFromPrepare: IczOption[] = [];
  leadingFile: Nullable<FileDto>;
  prepareValidationError = '';
  sameEntityClass = false;
  selectedFile: Nullable<FileDto>;

  isRetentionTriggerTypeWithPeriod = signal(false);
  isExternalRetentionTriggerTypeWithPeriod = signal(false);

  disposalOperationCodeOptions = enumToOptions('disposalOperationCode', DisposalOperationCode);
  retentionTriggerTypesOptions: IczOption[] = enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode);

  form = new IczFormGroup({
    fileId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    entityClassId: new IczFormControl<Nullable<number>>(null),
    disposalScheduleId: new IczFormControl<Nullable<number>>(null),
    conflictEntityClassId: new IczFormControl<Nullable<number>>(null),
    conflictDisposalScheduleId: new IczFormControl<Nullable<number>>(null),
    yearOfRetentionPeriodStart: new IczFormControl<Nullable<number>>(null),
    triggerEventCheckYear: new IczFormControl<Nullable<number>>(null),
    minRetentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    minDisposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    disposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    externalRetentionTriggerIds: new IczFormControl<Nullable<number[]>>(null),
    retentionTriggerTypeCode: new IczFormControl<Nullable<string>>(null),
  });

  isSCRPrepareLoaded = false;
  retentionTriggerValidationMode = new BehaviorSubject<RetentionTriggerWidgetMode>(RetentionTriggerWidgetMode.VALIDATED);

  get fileIdControl() {
    return this.form.get('fileId')! as IczFormControl;
  }

  get retentionTriggerYearCtrl() {
    return this.form.get('yearOfRetentionPeriodStart') as IczFormControl;
  }

  get retentionTriggerTypeCodeCtrl() {
    return this.form.get('retentionTriggerTypeCode') as IczFormControl;
  }

  get retentionTriggerCheckYearCtrl() {
    return this.form.get('triggerEventCheckYear') as IczFormControl;
  }

  get entityClassIdCtrl() {
    return this.form.get('entityClassId')!;
  }

  get disposalScheduleIdCtrl() {
    return this.form.get('disposalScheduleId')!;
  }

  get conflictEntityClassIdCtrl() {
    return this.form.get('conflictEntityClassId')!;
  }

  get conflictDisposalScheduleIdCtrl() {
    return this.form.get('conflictDisposalScheduleId')!;
  }

  get isFileSelected() {
    return this.fileIdControl.value;
  }

  get disposalScheduleSourceEntitySelectedId() {
    return this.conflictDisposalScheduleIdCtrl.value;
  }

  additionalFilterCriteria: FilterParam[] = [
    {
      fieldName: 'fileState',
      operator: FilterOperator.inSet,
      value: String([FileState.OPEN, FileState.SETTLED, FileState.CLOSED]),
    },
  ];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.form.get('fileId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(fileId => {
      this.isSCRPrepareLoaded = false;
      if (fileId === this.dialogData.id) {
        this.prepareValidationError = 'Spis není možné propojit pevným křížovým odkazem se sebou samým.';
        this.isSCRPrepareLoaded = true;
      } else {
        this.prepareValidationError = '';
        if (fileId) {
          this.loadingService.doLoading(
            this.apiCrossReferenceService.crossReferencePrepareStrongCrossReference({
              body: {
                fileforCrossReferenceFromId: this.dialogData.id,
                fileforCrossReferenceToId: fileId
              }
            }),
            this
          ).subscribe( prepare => {
            if (prepare.validatonErrorCode) {
              this.prepareValidationError = interpolateBackendErrorMessage(this.translateService, {
                errorCode: prepare.validatonErrorCode,
                parameters: [this.dialogData.refNumber!, this.selectedFile?.refNumber ?? '']
              });
            } else {
              this.allFilesForStrongCrossReference = prepare.filesForCrossReference;
              this.filesForStrongCrossReference = prepare.filesForCrossReference.filter(f => f.id !== this.dialogData.id);
              if (this.allFilesForStrongCrossReference) {
                this.leadingFile = this.allFilesForStrongCrossReference.find(f => f.id === prepare.leadingFileId);
                this.closedFilesForStrongCrossReference = this.filesForStrongCrossReference.filter(file => file.fileState === FileState.CLOSED);
                if (this.leadingFile) {
                  this.form.get('entityClassId')!.setValue(this.leadingFile.entityClassId);
                  this.form.get('disposalScheduleId')!.setValue(this.leadingFile.disposalScheduleId);
                }
                this.validateLeadingFileConflict();
              }
            }
            this.isSCRPrepareLoaded = true;
          });
        }
      }
    });

    this.conflictEntityClassIdCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(entityClassId => {
      if (entityClassId) {
        this.prepareDisposalSchedules(entityClassId);
      }
    });

    this.conflictDisposalScheduleIdCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      const selectedDs = this.disposalSchedulePrepare!.availableDisposalSchedules!.find(ds => ds.disposalScheduleId === value);
      this.fillRetentionSection(selectedDs);
    });

    this.loadingService.doLoading(
      this.configService.getPropValuesForCurrentOrganization([FileConfigKeys.FILE_SCR_LEADING_FILE_TYPE]),
      this
    ).subscribe(configValues => {
      this.fileSCRLeadingFile = configValues[FileConfigKeys.FILE_SCR_LEADING_FILE_TYPE].value as StrongCrossReferenceType;
    });
  }

  onFileSelected(files: FileDto[]) {
    this.selectedFile = files[0];
  }

  validateLeadingFileConflict(): void {
    if (this.leadingFile && this.leadingFile.id === this.dialogData.id) {
      // current file that is being added to SCR group will be the new leading file
      if (this.closedFilesForStrongCrossReference.length > 0 && this.leadingFile.disposalScheduleId !== this.closedFilesForStrongCrossReference[0].disposalScheduleId) {
        // there is a conflict between leading file entityClassId or disposalScheduleId, user must resolve this conflict
        this.isEntityClassConflict = true;
        this.entityClassIdCtrl.clearValidators();
        this.disposalScheduleIdCtrl.clearValidators();
        this.conflictEntityClassIdCtrl.setValidators([IczValidators.required()]);
        this.conflictDisposalScheduleIdCtrl.setValidators([IczValidators.required()]);
      } else {
        this.isEntityClassConflict = false;
      }
    } else {
      this.sameEntityClass = true;
      for (const f of this.allFilesForStrongCrossReference) {
        this.allFilesForStrongCrossReference.forEach(comparingFile => {
          if (f.id !== comparingFile.id && f.entityClassId !== comparingFile.entityClassId) {
            this.sameEntityClass = false;
          }
        });
        if (!this.sameEntityClass) {
          break;
        }
      }
    }
  }

  fillRetentionSection(selectedDs: Nullable<SimplifiedDisposalScheduleDto>) {
    if (selectedDs) {
      this.form.get('retentionTriggerTypeCode')!.setValue(selectedDs?.retentionTriggerTypeCode);
      this.form.get('disposalOperationCode')!.setValue(selectedDs?.disposalOperationCode);
      this.form.get('retentionPeriod')!.setValue(selectedDs.retentionPeriod);
      this.form.get('triggerEventCheckYear')!.setValue((new Date()).getFullYear() + (selectedDs.defaultTriggerEventCheckYear ?? 0));
    }
  }

  prepareDisposalSchedules(entityClassId: number) {
    const innerReq: DisposalScheduleComputationInnerRequestDto[] = [];
    this.closedFilesForStrongCrossReference.forEach(f=> {
      innerReq.push({
        entityId: f.id,
        entityClassId,
      });
    });
    const prepareReq: DisposalScheduleComputationRequestDto = {
      referenceDate: (new Date()).toISOString(),
      inner: innerReq
    };
    this.apiFileService.fileGetValidDisposalSchedules({body: prepareReq}).subscribe(result => {
      this.disposalSchedulePrepare = result.globalResult;
      if (this.disposalSchedulePrepare) {
        if (this.disposalSchedulePrepare.availableDisposalSchedules) {
          this.disposalScheduleOptionsFromPrepare = this.disposalSchedulePrepare.availableDisposalSchedules.map(ds => ({value: ds.disposalScheduleId, label: formatDisposalSchedule(ds)}));
          if (this.disposalSchedulePrepare.recommendedDisposalScheduleId) {
            const recommendedDS = this.disposalSchedulePrepare.availableDisposalSchedules.find(ds => ds.disposalScheduleId === this.disposalSchedulePrepare!.recommendedDisposalScheduleId)!;
            this.form.get('conflictDisposalScheduleId')!.setValue(recommendedDS.disposalScheduleId);
            if (recommendedDS) {
              this.form.get('minDisposalOperationCode')!.setValue(recommendedDS.disposalOperationCode);
              this.form.get('minRetentionPeriod')!.setValue(recommendedDS.retentionPeriod);
            }
          }
        }
        if (this.disposalSchedulePrepare.defaultYearOfRetentionPeriodStart) {
          this.form.get('yearOfRetentionPeriodStart')!.setValue(this.disposalSchedulePrepare.defaultYearOfRetentionPeriodStart);
        }
        this.form.get('externalRetentionTriggerIds')!.setValue(this.disposalSchedulePrepare.externalRetentionTriggerIds);
      }
    });
  }

  submit() {
    if (this.prepareValidationError === '') {
      this.loadingService.doLoading(
        this.apiCrossReferenceService.crossReferenceCreateStrongCrossReference({
          body: {
            entityReferringFromId: this.dialogData.id,
            entityReferringToId: this.form.get('fileId')!.value!,
            filesForCrossReference: this.allFilesForStrongCrossReference.map(f => f.id),
            entityClassId: this.isEntityClassConflict ? this.conflictEntityClassIdCtrl.value : this.entityClassIdCtrl.value,
            disposalScheduleId: this.isEntityClassConflict ? this.conflictDisposalScheduleIdCtrl.value : this.disposalScheduleIdCtrl.value,
          }
        }, SKIP_ERROR_DIALOG),
        this
      ).subscribe({
        next: _ => {
          this.form.markAsPristine();
          this.modalRef.close(true);
          this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_STRONG_CROSS_REFERENCE_CREATED, {
            [InternalNotificationKey.FILE_ID]: this.dialogData.id,
            [InternalNotificationKey.FILE_SUBJECT]: `${this.dialogData.refNumber} ${this.dialogData.subject}`
          });
        },
        error: err => {
          this.fileToastService.dispatchFileErrorToast(FileToastType.FILE_STRONG_CROSS_REFERENCE_CREATE_ERROR, {
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, err.error),
            [InternalNotificationKey.ERROR_PARAMETERS]: []
          });
        }
      });
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

  noConflictAlertText() {
    if (this.fileSCRLeadingFile) {
      const contextParams = {
        leadingFileType: this.fileSCRLeadingFile === StrongCrossReferenceType.OLDEST ? this.translateService.instant('nejdříve') : this.translateService.instant('nejpozději')
      };
      return this.translateParser.interpolate(
        this.translateService.instant('fe.ui.customFields.help.strongCrossReferenceCreate'),
        contextParams
      )!;
    } else {
      return '';
    }
  }

  protected readonly getTodayMidnight = getTodayMidnight;
  protected readonly FileState = FileState;
}
