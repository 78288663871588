@if (dataSource) {
  <icz-storage-units-table
    tableId="storage-unit-insert-selection"
    class="grow"
    [dataSource]="dataSource"
    [viewType]="StorageUnitView.STORAGE_UNIT_SELECT"
    (activeRowChanged)="itemSelected($event)"
    >
    <icz-label noDataLabel label="Žádné objekty"></icz-label>
  </icz-storage-units-table>
}
