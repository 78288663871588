import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {
  ApplicationConfigService,
  CheckUnsavedFormDialogService,
  CodebookService,
  IFormGroupCheckable,
  NewStorageUnitDialogResult
} from '|shared';
import {DisposalType} from '|api/commons';
import {ApiStorageUnitService, StorageUnitCreateDto} from '|api/document';
import {ApiIdentifierGeneratorService} from '|api/records';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {injectModalRef} from '@icz/angular-modal';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {forkJoin} from 'rxjs';
import {initStrictEnforcementsValidations} from '../storage-unit.forms';
import {
  getStorageUnitSpecificationForm
} from '../new-storage-unit-specification-form/new-storage-unit-specification-form.component';

@Component({
  selector: 'icz-new-storage-unit-dialog',
  templateUrl: './new-storage-unit-dialog.component.html',
  styleUrls: ['./new-storage-unit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class NewStorageUnitDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<NewStorageUnitDialogResult>>();
  private apiStorageUnitService = inject(ApiStorageUnitService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private applicationConfigService = inject(ApplicationConfigService);
  private apiIdentifierGeneratorService = inject(ApiIdentifierGeneratorService);
  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  form = getStorageUnitSpecificationForm();

  formGroupsToCheck!: string[];

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.loadingService.doLoading(
      forkJoin([
        this.apiIdentifierGeneratorService.identifierGeneratorGenerateStorageUnitNumber(),
        this.codebookService.disposalSchedules(),
      ]),
      this
    ).subscribe(([storageUnitNumber, disposalSchedules]) => {
      this.form.get('storageUnitNumber')!.setValue(storageUnitNumber.identifier);

      this.form.get('disposalScheduleId')!.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(disposalScheduleId => {
        if (disposalScheduleId) {
          const disposalSchedule = disposalSchedules.find(ds => ds.id === disposalScheduleId)!;

          if (disposalSchedule.disposalType === DisposalType.FIXED_DISPOSAL_YEAR) {
            this.form.get('disposalYear')!.setValue(disposalSchedule.disposalYear);
          }
        }
      });
    });

    initStrictEnforcementsValidations(this.form, this.destroyRef);

    if (this.applicationConfigService.requireEntityClassConsistency) {
      this.form.get('enforceStrictEntityClass')!.setValue(true);
    }
    if (this.applicationConfigService.requireDisposalYearConsistency) {
      this.form.get('enforceStrictDisposalYear')!.setValue(true);
    }
    if (this.applicationConfigService.forceRequiredDisposalSchedule) {
      this.form.get('enforceStrictDisposalSchedule')!.setValue(true);
    }
  }

  submit(redirectToPermissionSettings: boolean) {
    const formValue = this.form.getRawValue();

    const requestDto: StorageUnitCreateDto = {
      description: formValue.description,
      disposalScheduleId: formValue.disposalScheduleId,
      disposalYear: formValue.disposalYear,
      enforceStrictDisposalSchedule: formValue.enforceStrictDisposalSchedule!,
      enforceStrictDisposalYear: formValue.enforceStrictDisposalYear!,
      enforceStrictEntityClass: formValue.enforceStrictEntityClass!,
      entityClassId: formValue.entityClassId,
      name: formValue.name!,
      physicalLocation: formValue.physicalLocation,
      storageUnitForm: formValue.storageUnitForm!,
      storageUnitNumber: formValue.storageUnitNumber!,
    };

    this.loadingService.doLoading(
      this.apiStorageUnitService.storageUnitCreate({
        body: requestDto,
      }),
      this,
    ).subscribe(storageUnit => {
      this.modalRef.close({
        storageUnit,
        redirectToPermissionSettings,
      });
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
